import React, {useContext, useEffect, useState} from 'react';
import {Form, Input, Icon, Button, message, Upload, Modal, Select, InputNumber, Switch} from "antd";
import firebase from "../../firebase";
import config from "../../../config";
const { TextArea } = Input;
const Option = Select.Option;

const AddMenuItemForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(false);
    const [single, setSingle] = useState(props.single && props.single);

    const [restaurants, setRestaurants] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // load all restaurants
        firebase.getAllRestaurants((resp) => {
            setRestaurants(resp);
        });

        // load all restaurant categories
        firebase.getCategories((resp) => {
            setCategories(resp)
        });
    }, [])

    // convert image file for base64
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    // call before upload the image and decide the file types and size
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        // check if file size is not more then 2 mbps
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    // handle on change file uploader
    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                setImageUrl(imageUrl);
                if (single){
                    setSingle({
                        ...single,
                        image: ""
                    })
                }
                setLoading(false);
            });
        }
    };

    const randomString = (length) => {
        let chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    // generate random alphanumeric strings
    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (single) {
                    updateMenuItem(single.key, values);
                } else {
                    addNewMenunItem(values);
                }
            }
        });
    };

    // add new menu item
    const addNewMenunItem = (values) => {
        setLoading(true);
        if (values.image.file && values.image.fileList[0]) {
            // guess the image type
            let imageext = values.image.fileList[0].type === "image/jpeg" ? ".jpg" : ".png";
            // generate image name
            let imagename = randomString(20) + imageext;
            let uploadTask = firebase.storage.child('menus/' + imagename).put(values.image.fileList[0].originFileObj);
            uploadTask.on('state_changed', function (snapshot) {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            }, function (error) {
                Modal.error({
                    title: 'Sorry',
                    content: error
                })
                setLoading(false);
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    firebase.createMenuItem(values.restaurant, {
                        name: values.name,
                        categoryKey: values.categoryKey,
                        description: values.description,
                        price: parseFloat(values.price).toFixed(2).toString(),
                        can_add_notes: values.can_add_notes,
                        image: imagename
                    }, (addedresp) => {
                        if (addedresp.success) {
                            Modal.success({
                                title: "Success!",
                                content: "Menu item successfully added."
                            });
                            props.form.resetFields();
                            setImageUrl(false);
                            setLoading(false);
                            props.onSuccess();
                        } else {
                            Modal.error({
                                title: "Sorry!",
                                content: addedresp.msg
                            });
                            setLoading(false);
                        }
                    })
                });
            });
        } else {
            firebase.createMenuItem(values.restaurant,{
                name: values.name,
                categoryKey: values.categoryKey,
                description: values.description,
                price: parseFloat(values.price).toFixed(2).toString(),
                can_add_notes: values.can_add_notes,
                image: ""
            }, (addedresp) => {
                if (addedresp.success) {
                    Modal.success({
                        title: "Success!",
                        content: "Menu Item successfully added."
                    });
                    props.form.resetFields();
                    setImageUrl(false);
                    setLoading(false);
                    props.onSuccess();
                } else {
                    Modal.error({
                        title: "Sorry!",
                        content: addedresp.msg
                    });
                    setLoading(false);
                }
            })
        }
    }

    // update menu item
    const updateMenuItem = (id, values) => {
        if (single != undefined && single.image != undefined && values.image.fileList != undefined) {
            let imageext = values.image.fileList[0].type === "image/jpeg" ? ".jpg" : ".png"
            let imagename = randomString(20) + imageext;
            let uploadTask = firebase.storage.child('menus/' + imagename).put(values.image.fileList[0].originFileObj);
            uploadTask.on('state_changed', function (snapshot) {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            }, function (error) {
                Modal.error({
                    title: 'Sorry',
                    content: error
                })
                setLoading(false);
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    firebase.updateMenuItem(values.restaurant, id,{
                        name: values.name,
                        categoryKey: values.categoryKey,
                        description: values.description,
                        price: parseFloat(values.price).toFixed(2).toString(),
                        can_add_notes: values.can_add_notes,
                        image: imagename
                    }, (addedresp) => {
                        if (addedresp.success) {
                            Modal.success({
                                title: "Success!",
                                content: "Menu Item successfully updated."
                            });
                            props.form.resetFields();
                            setImageUrl(false);
                            setLoading(false);
                            props.onSuccess();
                        } else {
                            Modal.error({
                                title: "Sorry!",
                                content: addedresp.msg
                            });
                            setLoading(false);
                        }
                    })
                });
            });
        } else {
            firebase.updateMenuItem(values.restaurant, id,{
                name: values.name,
                categoryKey: values.categoryKey,
                description: values.description,
                price: parseFloat(values.price).toFixed(2).toString(),
                can_add_notes: values.can_add_notes,
                image: single.image ? single.image : ""
            }, (addedresp) => {
                if (addedresp.success) {
                    Modal.success({
                        title: "Success!",
                        content: "Menu Item successfully updated."
                    });
                    props.form.resetFields();
                    setImageUrl(false);
                    setLoading(false);
                    props.onSuccess();
                } else {
                    Modal.error({
                        title: "Sorry!",
                        content: addedresp.msg
                    });
                    setLoading(false);
                }
            })
        }
    }

    // create a dummy request for file uploader
    const dummyRequest = ({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const {getFieldDecorator} = props.form;

    // create the upload button with a loader
    const uploadButton = (
        <div>
            <Icon type={loading ? 'loading' : 'plus'}/>
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    return (
        <Form onSubmit={handleSubmit}>

            <Form.Item
                label="Select Restaurant"
            >
                {getFieldDecorator('restaurant', {
                    initialValue: single ? single.restaurant : "",
                    rules: [{required: true, message: 'Please input restaurant!'}],
                })(
                    <Select
                        placeholder='Select Restaurant'
                    >
                        {
                            restaurants &&
                            restaurants.map((item, index) => {
                                return <Option key={index} value={item.key}>{item.name}</Option>
                            })
                        }
                    </Select>
                )}
            </Form.Item>

            <Form.Item
                label="Select Category"
            >
                {getFieldDecorator('categoryKey', {
                    initialValue: single ? single.categoryKey : "",
                    rules: [{required: true, message: 'Please input category!'}],
                })(
                    <Select
                        placeholder='Select Category'
                    >
                        {
                            categories &&
                            categories.map((item, index) => {
                                return <Option key={index} value={item.key}>{item.name}</Option>
                            })
                        }
                    </Select>
                )}
            </Form.Item>

            <Form.Item
                label="Menu Name"
            >
                {getFieldDecorator('name', {
                    initialValue: single ? single.name : "",
                    rules: [{required: true, message: 'Please input menu name!'}],
                })(
                    <Input
                        placeholder="Menu Name"
                    />,
                )}
            </Form.Item>

            <Form.Item
                label="Menu Price"
            >
                {getFieldDecorator('price', {
                    initialValue: single ? single.price : "",
                    rules: [{required: true, message: 'Please input menu price!'}],
                })(
                    <InputNumber
                        min={0.01}
                        step={0.01}
                        style={{width: '100%'}}
                        placeholder="Menu Price"
                    />,
                )}
            </Form.Item>

            <Form.Item label="Can Add Notes">
                {getFieldDecorator('can_add_notes', {
                    valuePropName: 'checked',
                    initialValue: single ? single.can_add_notes : false
                })(
                    <Switch checkedChildren="Yes" unCheckedChildren="No"/>
                )}
            </Form.Item>

            <Form.Item
                label="Description"
            >
                {getFieldDecorator('description', {
                    initialValue: single ? single.description : "",
                })(
                    <TextArea
                        placeholder="Description"
                    />,
                )}
            </Form.Item>

            <Form.Item label="Select Menu Image">
                {getFieldDecorator('image', {
                    initialValue: [],
                    valuePropName: 'files',
                })(
                    <Upload
                        name="category_image"
                        listType="picture-card"
                        className="image-uploader"
                        showUploadList={false}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                    >
                        {
                            single.image ?
                                <img
                                    src={`https://firebasestorage.googleapis.com/v0/b/${config.storageBucket}/o/menus%2F${single.image}?alt=media`}
                                    alt="menu_category_image" style={{width: '100%'}}/> :
                                imageUrl ?
                                    <img src={imageUrl} alt="menu_category_image" style={{width: '100%'}}/>
                                    : uploadButton
                        }
                    </Upload>,
                )}
            </Form.Item>

            <Form.Item>
                <Button type="primary" disabled={loading} loading={loading} htmlType="submit" block>
                    {single ? "Update" : "Save"} Menu Item
                </Button>
                <Button type="danger" block
                        onClick={() => {
                            props.form.resetFields();
                            props.onCancel();
                            setLoading(false);
                        }}
                >
                    Cancel
                </Button>
            </Form.Item>
        </Form>
    );
};

const AddMenuItem = Form.create({name: 'addmenuitem'})(AddMenuItemForm);

export default AddMenuItem;
