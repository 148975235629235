import React from 'react';

import { withRouter } from 'react-router-dom'

import AdminLayout from "../components/AdminLayout";

class Dashboard extends React.Component {
  render() {
    return (
      <AdminLayout {...this.props}>
      </AdminLayout>
    );
  }
};

export default withRouter(Dashboard);
