import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom'
import firebase from '../../firebase'
import AdminLayout from "../../components/AdminLayout";
import Highlighter from 'react-highlight-words';

import {
    Icon, Table, Button, Input, Modal, Tabs, Card, Row, Col
} from 'antd';
import moment from "moment";
const { confirm } = Modal;
const { TabPane } = Tabs;

const { Search } = Input;
const ButtonGroup = Button.Group;

const Orders = (props) => {
    let searchInput;

    const [loadingPendingOrders, setLoadingPendingOrders] = useState(false);
    const [pendingOrders, setPendingOrders] = useState([]);
    const [filteredPendingOrders, setFilteredPendingOrders] = useState([]);

    const [loadingApprovedOrders, setLoadingApprovedOrders] = useState(false);
    const [approvedOrders, setApprovedOrders] = useState([]);
    const [filteredApprovedOrders, setFilteredApprovedOrders] = useState([]);

    const [loadingInProcessOrders , SetLoadingInProcessOrders] = useState(false);
    const [inProcessOrders, setInProcessOrders] = useState([]);

    const [loadingCancelledOrders, setLoadingCancelledOrders] = useState(false);
    const [cancelledOrders, setCancelledOrders] = useState([]);

    const [loadingCompletedOrders, setLoadingCompletedOrders] = useState(false);
    const [completedOrders, setCompletedOrders] = useState([]);
    const [filteredcompletedOrders, setFilteredCompletedOrders] = useState([]);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");

    const [orderviewModal, setOrderviewModal] = useState(false);
    const [single, setSingle] = useState(false);

    useEffect(() => {
        getAllPendingOrders();
        getAllApprovedOrders();
        getAllInProcessOrders();
        getAllCancelledOrders();
        getAllCompletedOrders();
    }, []);

    const getAllPendingOrders = () => {
        setLoadingPendingOrders(true);
        firebase.getOrders("pending", (data) => {
            setPendingOrders(data);
            setFilteredPendingOrders(data);
            setLoadingPendingOrders(false);
        })
    }

    const getAllApprovedOrders = () => {
        setLoadingApprovedOrders(true);
        firebase.getOrders("approved", (data) => {
            setApprovedOrders(data);
            setFilteredApprovedOrders(data);
            setLoadingApprovedOrders(false);
        })
    }

    const getAllInProcessOrders = () => {
        SetLoadingInProcessOrders(true);
        firebase.getOrders("In process", (data) => {
            setInProcessOrders(data);
            SetLoadingInProcessOrders(false);
        })
    }

    const getAllCancelledOrders = () => {
        setLoadingCancelledOrders(true);
        firebase.getOrders("cancelled", (data) => {
            setCancelledOrders(data);
            setLoadingCancelledOrders(false);
        })
    }

    const getAllCompletedOrders = () => {
        setLoadingCompletedOrders(true);
        firebase.getOrders("completed", (data) => {
            setCompletedOrders(data);
            setFilteredCompletedOrders(data);
            setLoadingCompletedOrders(false);
        })
    }

    const updateOrderStatus = (orderID, order_number, status) => {
        let statusLabel = "approve"
        if (status === "rejected") {
            statusLabel = "reject"
        } else if (status === "cancelled") {
            statusLabel = "cancel";
        } else if (status === "completed") {
            statusLabel = "complete";
        }

        confirm({
            title: 'Are you sure you want to ' + statusLabel + ' the selected orders?',
            content: '',
            icon: <Icon type="question-circle" />,
            okText: 'Yes',
            onOk() {
                firebase.db.ref('orders/' + orderID + '/' + order_number).update({ status: status, dateUpdated: firebase.serverTime })
                    .then(() => {
                        Modal.success({
                            title: 'Order Status',
                            content: 'Order status has been successfully ' + status + '!',
                        });
                    }).catch((error) => {
                        Modal.error({
                            title: 'Order Status',
                            content: error.message,
                        });
                    });
            },
            onCancel() { },
        });

    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text}
                />
            ) : (
                    text
                ),
    });

    const columns = [
        {
            title: 'Order Number',
            dataIndex: 'key',
            key: 'key',
            sorter: true,
            ...getColumnSearchProps('key'),
        },
        {
            title: 'Customer Details',
            dataIndex: 'customer_details',
            key: 'customer_details',
            ...getColumnSearchProps('customer_name'),
            render: (text, record) => (
                record.customer_name + `${record.customer_email && '['+record.customer_email+']'}`
            )
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => a.createdAt - b.createdAt,
            render: (text, record) => {
                return (typeof text === "string") ? record.createdAt : moment.unix(record.createdAt).format("LLL")
            }
        },
        {
            title: 'Actions',
            render: (text, record) => {
                return (
                    <ButtonGroup>
                        <Button
                            type="primary"
                            size="small"
                            icon="eye"
                            onClick={() => {
                                setSingle(record);
                                setOrderviewModal(true);
                            }}
                        />
                        <Button
                            type="danger"
                            size="small"
                            icon="delete"
                            onClick={() => {
                                Modal.confirm({
                                    title: 'Do you want to delete these order?',
                                    content: 'Once you delete this order, you cannot retrieve later.',
                                    onOk() {
                                        return firebase.db.ref().child('orders/'+record.nodeid+'/'+record.key).set(null);
                                    },
                                    onCancel() {},
                                })
                            }}
                        />
                    </ButtonGroup>
                )
            }
        },

    ];
    let pendingColums=[];
    let approvedColumns=[];

    let pendingAction =[{
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: (text, record) => (
            <ButtonGroup>
                <Button size="small" onClick={() => updateOrderStatus(record.id, record.key, "approved")}>
                    Approve
                </Button>
                <Button size="small" type="danger" onClick={() => updateOrderStatus(record.id, record.key, "rejected")}>
                    Reject
                </Button>
            </ButtonGroup>
        ),
    }];
    pendingColums=[...columns, ...pendingAction];

    let approvedActions = [{
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: (text, record) => (
            <ButtonGroup>
                <Button size="small" onClick={() => updateOrderStatus(record.id, record.key, "completed")}>
                    Complete
                </Button>
                <Button size="small" type="danger" onClick={() => updateOrderStatus(record.id, record.key, "cancelled")}>
                    Cancel
                </Button>
            </ButtonGroup>
        ),
    }];
    approvedColumns=[...columns, ...approvedActions];

    return (
        <AdminLayout {...props}>
            <div>
                <Card title="Orders">
                    <Tabs defaultActiveKey="2">
                        <TabPane tab="Completed Orders" key="1">
                            <div style={{
                                display: "flex",
                                justifyContent: 'space-between',
                                width: '100%',
                                marginBottom: 10
                            }}>
                                <div></div>
                                <div>
                                    <Search
                                        placeholder="Search order(s) by id or customer name"
                                        onChange={e => {
                                            const thfilteredMenus = completedOrders.filter(({ key, customer_name, customer_email }) => {
                                                let customerInfo = customer_name + (customer_email && '['+customer_email+']');
                                                customerInfo = customerInfo.toLowerCase();
                                                return (key.includes(e.target.value.toLowerCase()) || customerInfo.includes(e.target.value.toLowerCase()));
                                            });
                                            setFilteredCompletedOrders(thfilteredMenus);
                                        }}
                                        onSearch={value => {
                                            const thfilteredMenus = completedOrders.filter(({ key, customer_name, customer_email }) => {
                                                let customerInfo = customer_name + (customer_email && '['+customer_email+']');
                                                customerInfo = customerInfo.toLowerCase();
                                                return (key.includes(value.toLowerCase()) || customerInfo.includes(value.toLowerCase()));
                                            });
                                            setFilteredCompletedOrders(thfilteredMenus);
                                        }}
                                        style={{ width: 300 }}
                                        allowClear={true}
                                    />
                                </div>
                            </div>
                            <Table
                                size="small"
                                loading={loadingCompletedOrders}
                                columns={columns}
                                dataSource={filteredcompletedOrders}
                            />
                        </TabPane>
                        <TabPane tab="Pending Orders" key="2">
                            <div style={{
                                display: "flex",
                                justifyContent: 'space-between',
                                width: '100%',
                                marginBottom: 10
                            }}>
                                <div></div>
                                <div>
                                    <Search
                                        placeholder="Search order(s) by id or customer name"
                                        onChange={e => {
                                            const thfilteredMenus = pendingOrders.filter(({ key, customer_name, customer_email }) => {
                                                let customerInfo = customer_name + (customer_email && '['+customer_email+']');
                                                customerInfo = customerInfo.toLowerCase();
                                                return (key.includes(e.target.value.toLowerCase()) || customerInfo.includes(e.target.value.toLowerCase()));
                                            });
                                            setFilteredPendingOrders(thfilteredMenus);
                                        }}
                                        onSearch={value => {
                                            const thfilteredMenus = pendingOrders.filter(({ key, customer_name, customer_email }) => {
                                                let customerInfo = customer_name + (customer_email && '['+customer_email+']');
                                                customerInfo = customerInfo.toLowerCase();
                                                return (key.includes(value.toLowerCase()) || customerInfo.includes(value.toLowerCase()));
                                            });
                                            setFilteredPendingOrders(thfilteredMenus);
                                        }}
                                        style={{ width: 300 }}
                                        allowClear={true}
                                    />
                                </div>
                            </div>
                            <Table
                                size="small"
                                loading={loadingPendingOrders}
                                columns={pendingColums}
                                dataSource={filteredPendingOrders}
                            />
                        </TabPane>
                        <TabPane tab="Orders In Process" key="4">
                            <Table
                                size="small"
                                loading={loadingInProcessOrders}
                                columns={columns}
                                dataSource={inProcessOrders}
                            />
                        </TabPane>
                        <TabPane tab="Approved Orders" key="3">
                            <Table
                                size="small"
                                loading={loadingApprovedOrders}
                                columns={approvedColumns}
                                dataSource={filteredApprovedOrders}
                            />
                        </TabPane>

                        <TabPane tab="Cancelled Orders" key="5">
                            <Table
                                size="small"
                                loading={loadingCancelledOrders}
                                columns={columns}
                                dataSource={cancelledOrders}
                            />
                        </TabPane>
                    </Tabs>

                </Card>
            </div>
            <Modal
                width="70%"
                title={`View Order: # ${single.key}`}
                visible={orderviewModal}
                onCancel={() => {
                    setSingle(false);
                    setOrderviewModal(false);
                }}
                onOk={() => {
                    setSingle(false);
                    setOrderviewModal(false);
                }}
            >
                <Row>
                    <Col xs={{span: 24}} lg={{span: 12}}>
                        <h4>Customer:</h4>
                        <div>
                            <h5>Customer Name: {single.customer_name}</h5>
                            <h5>Customer Email: {single.customer_email}</h5>
                        </div>
                    </Col>
                    <Col xs={{span: 24}} lg={{span: 12}}>
                        <h4>Order information:</h4>
                        <h5>Order Date: {(typeof single.createdAt === "string") ? single.createdAt : moment.unix(single.createdAt).format("LLL")}</h5>
                    </Col>
                </Row>

                <h4>Products:</h4>
                <Row style={{borderBottom: '1px solid'}}>
                    <Col xs={{span: 24}} lg={{span: 8}}><strong>Restaurant</strong></Col>
                    <Col xs={{span: 24}} lg={{span: 7}}><strong>Menu</strong></Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'center'}}><strong>Quantity</strong></Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}><strong>Unit Price</strong></Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}><strong>Total Price</strong></Col>
                </Row>
                {
                    single &&
                    single.products.map((pitem, pIndex) => {
                        return (
                            <Row style={{borderBottom: '1px solid'}} key={pIndex}>
                                <Col xs={{span: 24}} lg={{span: 8}}>{pitem.restaurant_name}</Col>
                                <Col xs={{span: 24}} lg={{span: 7}}>{pitem.name}</Col>
                                <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'center'}}>{pitem.quantity}</Col>
                                <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}>${parseFloat(pitem.price).toFixed(2)}</Col>
                                <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}>${parseFloat(parseInt(pitem.quantity)*parseFloat(pitem.price)).toFixed(2)}</Col>
                            </Row>
                        );
                    })
                }
                <Row style={{marginTop: 10, }}>
                    <Col xs={{span: 24}} lg={{span: 8}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 7}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'center'}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}><strong>Tax:</strong></Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}>${parseFloat(single.tax).toFixed(2)}</Col>
                </Row>
                <Row>
                    <Col xs={{span: 24}} lg={{span: 8}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 7}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'center'}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}><strong>Discount:</strong></Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}>${parseFloat(single.discount).toFixed(2)}</Col>
                </Row>
                <Row>
                    <Col xs={{span: 24}} lg={{span: 8}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 7}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'center'}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}><strong>Donate:</strong></Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}>${parseFloat(single.donate).toFixed(2)}</Col>
                </Row>
                <Row>
                    <Col xs={{span: 24}} lg={{span: 8}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 7}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'center'}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}><strong>Service fee:</strong></Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}>${parseFloat(single.servicefee).toFixed(2)}</Col>
                </Row>
                <Row>
                    <Col xs={{span: 24}} lg={{span: 8}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 7}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'center'}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}><strong>Delivery Fee:</strong></Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}>${parseFloat(single.deliveryfee).toFixed(2)}</Col>
                </Row>
                <Row>
                    <Col xs={{span: 24}} lg={{span: 8}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 7}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'center'}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}><strong>Tip:</strong></Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right'}}>${parseFloat(single.tipAmount).toFixed(2)}</Col>
                </Row>
                <Row>
                    <Col xs={{span: 24}} lg={{span: 8}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 7}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'center'}}>&nbsp;</Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right', borderTop: '1px solid'}}><strong>Total:</strong></Col>
                    <Col xs={{span: 24}} lg={{span: 3}} style={{textAlign: 'right', borderTop: '1px solid'}}>$<strong>{parseFloat(single.total).toFixed(2)}</strong></Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col xs={{span: 24}} lg={{span: 12}}>
                        <h4>Delivery Information:</h4>
                        <div>
                            <h5>Contact Name: {single && single.delivery.contactinfo}</h5>
                            <h5>Payment Method: {single && single.delivery.paymentmethod}</h5>
                            <h5>Email: {single && single.delivery.emailAddress}</h5>
                            <h5>Phone Number: {single && single.delivery.phonenumber}</h5>
                            <h5>Delivery Address: {single && single.delivery.deliveryaddress}</h5>
                            <h5>Street: {single && single.delivery.streetname}</h5>
                        </div>
                    </Col>
                    <Col xs={{span: 24}} lg={{span: 12}}>
                        <h4>Questionaire:</h4>
                        <div>
                            <h5>Customer Location: {single && single.questionaire.locationtype}</h5>
                            <h5>Flag number: {single && single.questionaire.flagbox}</h5>
                            <h5>Number of people in party: {single && single.questionaire.numberOfPeopleInParty}</h5>
                            <h5>Seated Closer: {single && single.questionaire.seated_closer}</h5>
                            <h5>Selfie</h5>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                <img style={{width: '30%'}} src={(single && single.questionaire.selfieURL) ? single.questionaire.selfieURL : undefined}/>
                            </div>
                            <h5>Umbrella: {single && single.questionaire.umbrella}</h5>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </AdminLayout>
    );
};

export default withRouter(Orders);
