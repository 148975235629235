import React from 'react'

import {
  Form, Icon, Input, Button, Modal
} from 'antd';

import firebase from '../firebase'

class ForgotPasswordFormWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      processing: false
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.resetPassword(values.email);
      }
    });
  }

  resetPassword(email) {
    var _this = this;

    this.setState({
      processing: true
    });

    firebase.sendPasswordResetEmail(email)
    .then(function() {
      _this.props.onSuccess(true);

      _this.setState({
        processing: false
      });
    }).catch(function(error) {
      Modal.error({
        title: 'Error',
        content: error.message,
      });

      _this.setState({
        processing: false
      });
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} className="login-form" style={{width: '85%'}}>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input type='email' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
          )}
        </Form.Item>
        <Form.Item>
          <a className="login-form-forgot" href="/login">Back to login</a>
          <Button disabled={this.state.processing} type="primary" htmlType="submit" block>
            {
              this.state.processing ?
                <span>Please wait . . .</span> :
                <span>Send instructions</span>
            }
          </Button>
        </Form.Item>
      </Form>
    )
  }

}

const ForgotPasswordForm = Form.create()(ForgotPasswordFormWrapper);

export default ForgotPasswordForm
