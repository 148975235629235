import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom'
import firebase from '../../firebase'
import AdminLayout from "../../components/AdminLayout";
import { Link } from "react-router-dom";
import Highlighter from 'react-highlight-words';
import EditUser from "./edit";

import {
    Icon, Table, Avatar, Button, Input, Modal, Tabs, Card
} from 'antd';
import { } from 'antd';

const { TabPane } = Tabs;
const Users = (props) => {
    let searchInput;
    const [loadingCustomers, setLoadingCustomers] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});

    const [loadingDrivers, setLoadingDrivers] = useState(false);
    const [drivers, setDrivers] = useState([]);

    const [loadingUserRestaurants, setLoadingUserRestaurants] = useState(false);
    const [userrestaurants, setUserRestaurants] = useState([]);

    const [loadingAdministrators, setLoadingAdministrators] = useState(false);
    const [administrators, setAdministrators] = useState([]);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [isVisible, setVisibility] = useState(false);

    useEffect(() => {
        getAllCustomers();
        getAllDrivers();
        getAllAdministrator();
        getAllUserRestaurant();
    }, []);

    function callback(key) {
        console.log(key);
    }

    const openEditForm = (isOpen) => {
        setVisibility(isOpen);
    };

    const deleteUser = (userID, authID) => {
        Modal.confirm({
            title: 'Are you sure you want to delete the selected user?',
            content: 'Cannot be retrieved after deletion',
            icon: <Icon type="question-circle" />,
            okText: 'Yes',
            onOk() {
                const userToBeDeleted = firebase.db.ref('users/' + userID);
                userToBeDeleted.once('value').then((snapshot) => {
                    var user = snapshot.val();

                    if (user.avatar !=="") {
                        let imagepath = user.avatar.split("/");
                        let image = imagepath[imagepath.length - 1].split("?");
                        image = image[0].split("%2F");
                        var imgRef = firebase.storage.child('profile_image/' + image[1]);
                        imgRef.delete();
                    }
                    userToBeDeleted.remove()
                        .then(() => {
                            //firebase.authUser.deleteUser(authID);
                            Modal.success({
                                content: 'User has been successfully deleted!'
                            })
                        })
                        .catch((error) => {
                            Modal.error({
                                title: 'Deleting user failed',
                                content: error.message,
                            });
                        });
                });

            },
            onCancel() { },
        });
    }

    const getAllCustomers = () => {
        setLoadingCustomers(true);
        firebase.getCustomers((data) => {
            setCustomers(data);
            setLoadingCustomers(false);
        });
    }

    const getAllDrivers = () => {
        setLoadingDrivers(true);
        firebase.getDrivers((data) => {
            setDrivers(data);
            setLoadingDrivers(false);
        });
    }

    const getAllUserRestaurant = () => {
        setLoadingUserRestaurants(true);
        firebase.getUserRestaurants((data) => {
            setUserRestaurants(data);
            setLoadingUserRestaurants(false);
        });
    }

    const getAllAdministrator = () => {
        setLoadingAdministrators(true);
        firebase.getAdministrators((data) => {
            setAdministrators(data);
            setLoadingAdministrators(false);
        });
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text}
                />
            ) : (
                    text
                ),
    });

    const columns = [
        {
            title: 'Image',
            dataIndex: 'avatar',
            key: 'avatar',
            render: (text) =>
                text === "" ? (
                    <Avatar size={64} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} >U</Avatar>
                ) : (
                        <Avatar size={64} src={text} />
                    ),

        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            ...getColumnSearchProps('address'),
        },
        {
            title: 'Contact No.',
            dataIndex: 'contact_number',
            key: 'contact_number',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button type="primary" onClick={() => { openEditForm(true); setSelectedUser(record) }} style={{ marginRight: 8 }} >Edit</Button>
                    <Button type="danger" onClick={() => deleteUser(record.key, record.authID)}>
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    return (
        <AdminLayout {...props}>
            <div>
                <div class="page-actions">
                  <Button type="primary" style={{ marginBottom: 10 }}>
                      <Link to="/user/add">
                          <Icon type="user-add" />
                          <span className="nav-text"> Add User</span>
                      </Link>
                  </Button>
                </div>
                <Card title="Users">
                <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="Customers" key="1">
                        <Table
                            size="small"
                            loading={loadingCustomers}
                            columns={columns}
                            dataSource={customers}
                        />
                    </TabPane>
                    <TabPane tab="Drivers" key="2">
                    <Table
                            size="small"
                            loading={loadingDrivers}
                            columns={columns}
                            dataSource={drivers}
                        />
                    </TabPane>
                    <TabPane tab="Restaurants" key="3">
                        <Table
                            size="small"
                            loading={loadingUserRestaurants}
                            columns={columns}
                            dataSource={userrestaurants}
                        />
                    </TabPane>
                    <TabPane tab="Administrators" key="4">
                    <Table
                            size="small"
                            loading={loadingAdministrators}
                            columns={columns}
                            dataSource={administrators}
                        />
                    </TabPane>
                </Tabs>

                </Card>
            </div>
            <EditUser openEditForm={openEditForm} isVisible={isVisible} selectedUser={selectedUser} />
        </AdminLayout>
    );
};

export default withRouter(Users);
