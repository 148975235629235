import React, {useContext, useEffect, useState} from 'react';
import {Form, Input, Icon, Button, message,
    Upload, Modal, Col, Row, Select, Spin, InputNumber, TimePicker} from "antd";
import firebase from "../../firebase";
import config from "../../../config";
import axios from 'axios';
import moment from 'moment';

const TextArea = Input.TextArea;
const Option = Select.Option;

const AddRestaurantForm = (props) => {
    // set restaurants and loading restaurants
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(false);

    // load all users if user_type is restaurants
    const [loadingRestaurantsUser, setLoadingRestaurantsUser] = useState(false);
    const [restaurantsUsers, setRestaurantsUsers] = useState(false);
    const [specialities, setSpecialities] = useState(false);

    // set address field
    // load address search
    // set addresses from api result
    const [searchInput, setSearchInput] = useState("");
    const [loadingAdddress, setLoadingAdddress] = useState(false);
    const [searchdddress, setSearchdddress] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState([]);

    // timings
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const [single, setSingle] = useState(props.single && props.single);

    useEffect(() => {
        // get all restaurants owner
        getAllUserRestaurant();
        loadallSpecialities();
        if (props.single) {
            searchPlaces(props.single.address);
            let center = [];
            center[0] = props.single.lng;
            center[1] = props.single.lat;
            setSelectedAddress(center);
        }
    }, [])

    const getAllUserRestaurant = () => {
        setLoadingRestaurantsUser(true);
        firebase.getUserRestaurants((data) => {
            // set all restaurant owner
            setRestaurantsUsers(data);
            setLoadingRestaurantsUser(false);
        });
    }

    // get specialities
    const loadallSpecialities = () => {
        firebase.getSpecialities((resp) => {
            setSpecialities(resp);
        });
    }

    // search places using mapbox rest api
    const searchPlaces = (text) => {
        setSearchInput(text);
        setLoadingAdddress(true);
        // create the search string
        let searchstring = encodeURIComponent(text)+".json";
        // set the places tyes
        let types = encodeURIComponent('address');
        // create the rest url
        let MBURL = "https://api.mapbox.com/geocoding/v5/mapbox.places/"+searchstring+"?types="+types+"&access_token=pk.eyJ1IjoidGFyZXFheml6MDA2NSIsImEiOiJjamNvbjQ3cnAyNXgyMzNybnlmN3p5NGFkIn0.zbs39bVfUf9ztz3AxnNTDg";
        setSearchdddress([]);
        axios.get(MBURL)
            .then((resp) => {
                // render the addresses
                let places = renderAddress(resp.data.features);
                // set the places found while searching
                setSearchdddress(places);
                setLoadingAdddress(false);
            })
            .catch((err) => {
                setLoadingAdddress(false);
                console.log('err', err);
            });
    }

    // render the addresses array form the features collections
    const renderAddress = (features) => {
        let addesses = [];
        for (let a in features) {
            addesses.push({
                name: features[a].place_name,
                center: features[a].center,
                id: features[a].id,
                key: features[a].id,
                text: features[a].text,
            })
        }
        return addesses;
    }

    // create base 64 image from file
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    // call before upload the image and decide the file types and size
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        // check if file size is not more then 2 mbps
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    // handle on change file uploader
    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                setImageUrl(imageUrl);
                if (single){
                    setSingle({
                        ...single,
                        image: ""
                    })
                }
                setLoading(false);
            });
        }
    };

    // generate random alphanumeric strings
    const randomString = (length) => {
        let chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    // submit the form
    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            // check if the form validated
            if (!err) {
                if (single) {
                    updateRestaurant(single.key, values);
                } else {
                    addNewRestaurant(values);
                }
            }
        });
    };

    // add new restaurant
    const addNewRestaurant = (values) => {
        setLoading(true);
        values.lat = selectedAddress ? selectedAddress[1] : 0;
        values.lng = selectedAddress ? selectedAddress[0] : 0;
        if (values.image.length > 0 && values.image.fileList[0]) {
            let imageext = values.image.fileList[0].type === "image/jpeg" ? ".jpg" : ".png"
            let imagename = randomString(20) + imageext;
            let uploadTask = firebase.storage.child('restaurant_image/' + imagename).put(values.image.fileList[0].originFileObj);
            uploadTask.on('state_changed', function (snapshot) {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            }, function (error) {
                Modal.error({
                    title: 'Sorry',
                    content: error
                })
                setLoading(false);
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    // check if file is successfully uploaded
                    firebase.createrestaurant({
                        address: values.address,
                        name: values.name,
                        customprice: parseFloat(values.customprice).toFixed(2),
                        fee: parseFloat(values.fee).toFixed(2),
                        description: values.description,
                        expenserate: values.expenserate,
                        restaurantOwner: values.restaurantOwner,
                        foodspeciality: values.foodspeciality,
                        fooddeliverytime: values.fooddeliverytime,
                        tax: parseFloat(values.tax).toFixed(2),
                        lat: values.lat,
                        lng: values.lng,
                        current_status: "approved",
                        timings: {
                            opening: moment(values.timings.opening).format("HH:mm a"),
                            closing: moment(values.timings.closing).format("HH:mm a"),
                            weekdays: values.timings.weekdays,
                        },
                        image: imagename,
                        status: 1,
                    }, (addedresp) => {
                        if (addedresp.success) {
                            Modal.success({
                                title: "Success!",
                                content: "Restaurant successfully added."
                            });
                            setSearchInput("");
                            // reset form if restaurant successfully added
                            setImageUrl(false);
                            props.form.resetFields();
                            setLoading(false);
                            props.onSuccess();
                        } else {
                            Modal.error({
                                title: "Sorry!",
                                content: addedresp.msg
                            });
                            setLoading(false);
                        }
                    })
                });
            });
        } else {
            firebase.createrestaurant({
                address: values.address,
                name: values.name,
                customprice: parseFloat(values.customprice).toFixed(2),
                fee: parseFloat(values.fee).toFixed(2),
                description: values.description,
                expenserate: values.expenserate,
                restaurantOwner: values.restaurantOwner,
                foodspeciality: values.foodspeciality,
                fooddeliverytime: values.fooddeliverytime,
                tax: parseFloat(values.tax).toFixed(2),
                lat: values.lat,
                lng: values.lng,
                current_status: "approved",
                timings: {
                    opening: moment(values.timings.opening).format("HH:mm a"),
                    closing: moment(values.timings.closing).format("HH:mm a"),
                    weekdays: values.timings.weekdays,
                },
                image: "",
                status: 1,
            }, (addedresp) => {
                if (addedresp.success) {
                    Modal.success({
                        title: "Success!",
                        content: "Restaurant successfully added."
                    });
                    setSearchInput("");
                    // reset the form if resaurant successfully added
                    setImageUrl(false);
                    props.form.resetFields();
                    setLoading(false);
                    props.onSuccess();
                } else {
                    Modal.error({
                        title: "Sorry!",
                        content: addedresp.msg
                    });
                    setLoading(false);
                }
            })
        }
    }

    // update restaurant
    const updateRestaurant = (id, values) => {
        setLoading(true);
        values.lat = selectedAddress ? selectedAddress[1] : 0;
        values.lng = selectedAddress ? selectedAddress[0] : 0;
        if (single && !single.image && values.image.fileList[0]) {
            let imageext = values.image.fileList[0].type === "image/jpeg" ? ".jpg" : ".png"
            let imagename = randomString(20) + imageext;
            let uploadTask = firebase.storage.child('restaurant_image/' + imagename).put(values.image.fileList[0].originFileObj);
            uploadTask.on('state_changed', function (snapshot) {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            }, function (error) {
                Modal.error({
                    title: 'Sorry',
                    content: error
                })
                setLoading(false);
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    firebase.updateRestaurant(id,{
                        address: values.address,
                        name: values.name,
                        customprice: parseFloat(values.customprice).toFixed(2),
                        description: values.description,
                        expenserate: values.expenserate,
                        restaurantOwner: values.restaurantOwner,
                        foodspeciality: values.foodspeciality,
                        fooddeliverytime: values.fooddeliverytime,
                        tax: parseFloat(values.tax).toFixed(2),
                        lat: values.lat,
                        lng: values.lng,
                        current_status: "approved",
                        timings: {
                            opening: moment(values.timings.opening).format("HH:mm a"),
                            closing: moment(values.timings.closing).format("HH:mm a"),
                            weekdays: values.timings.weekdays,
                        },
                        image: imagename,
                        status: 1,
                    }, (addedresp) => {
                        if (addedresp.success) {
                            Modal.success({
                                title: "Success!",
                                content: "Restaurant successfully updated."
                            });
                            setSearchInput("");
                            // reset the fields if restaurant successfully updated
                            props.form.resetFields();
                            setLoading(false);
                            props.onSuccess();
                        } else {
                            Modal.error({
                                title: "Sorry!",
                                content: addedresp.msg
                            });
                            setLoading(false);
                        }
                    })
                });
            });
        } else {
            firebase.updateRestaurant(id,{
                address: values.address,
                name: values.name,
                customprice: parseFloat(values.customprice).toFixed(2),
                description: values.description,
                expenserate: values.expenserate,
                restaurantOwner: values.restaurantOwner,
                foodspeciality: values.foodspeciality,
                fooddeliverytime: values.fooddeliverytime,
                tax: parseFloat(values.tax).toFixed(2),
                lat: values.lat,
                lng: values.lng,
                current_status: "approved",
                timings: {
                    opening: moment(values.timings.opening).format("HH:mm a"),
                    closing: moment(values.timings.closing).format("HH:mm a"),
                    weekdays: values.timings.weekdays,
                },
                image: single.image ? single.image : "",
                status: 1,
            }, (addedresp) => {
                if (addedresp.success) {
                    Modal.success({
                        title: "Success!",
                        content: "Restaurant successfully updated."
                    });
                    setSearchInput("");
                    // reset the fields if restaurant successfully updated
                    props.form.resetFields();
                    setLoading(false);
                    props.onSuccess();
                } else {
                    Modal.error({
                        title: "Sorry!",
                        content: addedresp.msg
                    });
                    setLoading(false);
                }
            })
        }
    }

    // create a dummy request for file uploader
    const dummyRequest = ({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const {getFieldDecorator} = props.form;

    // create upload button with a loader
    const uploadButton = (
        <div>
            <Icon type={loading ? 'loading' : 'plus'}/>
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    return (
        <Form onSubmit={handleSubmit}>

            <Row gutter={16}>
                <Col xs={{span: 24}} lg={{span: 12}}>
                    <Form.Item
                        label="Restaurant name"
                    >
                        {getFieldDecorator('name', {
                            initialValue: single ? single.name : "",
                            rules: [{required: true, message: 'Please input restaurant name!'}],
                        })(
                            <Input
                                placeholder="Restaurant name"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item
                        label="Restaurant owner"
                    >
                        {getFieldDecorator('restaurantOwner', {
                            initialValue: single ? single.restaurantOwner : "",
                            rules: [{required: true, message: 'Please select restaurant owner!'}],
                        })(
                            <Select
                                loading={loadingRestaurantsUser}
                            >
                                {
                                    restaurantsUsers &&
                                    restaurantsUsers.map((item, index) => {
                                        return <Option key={index} value={item.email}>{item.name}</Option>;
                                    })
                                }
                            </Select>,
                        )}
                    </Form.Item>

                    <Form.Item
                        label="Select Food speciality"
                    >
                        {getFieldDecorator('foodspeciality', {
                            initialValue: single ? single.foodspeciality : "",
                            rules: [{required: true, message: 'Please select food speciality!'}],
                        })(
                            <Select>
                                {
                                    specialities &&
                                    specialities.map((item, index) => {
                                        return <Option key={index} value={item.name}>{item.name}</Option>;
                                    })
                                }
                            </Select>,
                        )}
                    </Form.Item>

                    <Form.Item label="Food delivery time (in minutes)">
                        {getFieldDecorator('fooddeliverytime', {
                            initialValue: single ? single.fooddeliverytime : 0,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please food delivery time',
                                },
                            ],
                        })(<InputNumber
                            style={{width: '100%'}}
                            placeholder="Food delivery time"
                            min={1} step={1}/>)}
                    </Form.Item>

                    <Form.Item label="Expense Rate">
                        {getFieldDecorator('expenserate', {
                            initialValue: single ? single.expenserate : "",
                            rules: [
                                {
                                    required: true,
                                    message: 'Please type expense rate',
                                },
                            ],
                        })(<Input placeholder="Expense Rate"/>)}
                    </Form.Item>

                    <Form.Item label="Tax rate (in %)">
                        {getFieldDecorator('tax', {
                            initialValue: single ? single.tax : "",
                            rules: [
                                {
                                    required: true,
                                    message: 'Please type tax rate',
                                },
                            ],
                        })(<InputNumber
                            style={{width: '100%'}}
                            placeholder="Tax Rate"
                            min={0.00} step={0.01}/>
                        )}
                    </Form.Item>

                    <Form.Item label="Custom price">
                        {getFieldDecorator('customprice', {
                            initialValue: single ? single.customprice : "",
                        })(<InputNumber
                            style={{width: '100%'}}
                            placeholder="Custom price"
                            min={0.00} step={0.01}/>
                        )}
                    </Form.Item>

                    <Form.Item label="Delivery Fee">
                        {getFieldDecorator('fee', {
                            initialValue: single ? single.fee : "",
                        })(<InputNumber
                            style={{width: '100%'}}
                            placeholder="Delivery Fee"
                            min={0.00} step={0.01}/>
                        )}
                    </Form.Item>

                </Col>
                <Col xs={{span: 24}} lg={{span: 12}}>

                    <h4 style={{marginTop: 20,}}>Timings</h4>
                    <Row gutter={16}>
                        <Col xs={{span: 12}} lg={{span: 12}}>

                            <Form.Item label="Opening">
                                {getFieldDecorator("timings['opening']", {
                                    initialValue: (single && single.timings) ? moment(single.timings.opening, 'HH:mm a') : moment('00:00 am', 'HH:mm a'),
                                })(<TimePicker
                                    style={{width: '100%'}}
                                    format="HH:mm a"
                                    use12Hours
                                    defaultOpenValue={moment('00:00 am', 'HH:mm a')} />
                                )}
                            </Form.Item>

                        </Col>
                        <Col xs={{span: 12}} lg={{span: 12}}>

                            <Form.Item label="Closing">
                                {getFieldDecorator("timings['closing']", {
                                    initialValue: (single && single.timings) ? moment(single.timings.closing, 'HH:mm a') : moment('00:00 am', 'HH:mm a'),
                                })(<TimePicker
                                    style={{width: '100%'}}
                                    format="HH:mm a"
                                    use12Hours
                                    defaultOpenValue={moment('00:00 am', 'HH:mm a')} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item label="Week Days">
                        {getFieldDecorator("timings['weekdays']", {
                            initialValue: (single && single.timings) ? single.timings.weekdays : weekdays,
                        })(
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                            >
                                {weekdays.map(item => (
                                    <Select.Option key={item} value={item}>
                                        {item}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>

                    <h4 style={{marginTop: 20,}}>Address</h4>
                    <Form.Item label="Address">
                        {getFieldDecorator('address', {
                            initialValue: single ? single.address : searchInput,
                            valuePropName: 'option',
                            rules: [
                                {
                                    required: true,
                                    message: 'Please type restaurant address',
                                },
                            ],
                        })(
                            <Select
                                showSearch
                                placeholder="Type and select address"
                                notFoundContent={loadingAdddress ? <Spin size="small" /> : null}
                                filterOption={false}
                                defaultValue={single && single.address}
                                onSearch={searchPlaces}
                                onChange={(value, e) => {
                                    setSelectedAddress(e.props.center);
                                    searchPlaces(false);
                                    setLoadingAdddress(false);
                                }}
                                style={{ width: '100%' }}
                            >
                                {searchdddress && searchdddress.map(d => (
                                    <Option key={d.key} value={d.name} center={d.center}>{d.name}</Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="Select Restaurant Image"
                    >
                        {getFieldDecorator('image', {
                            initialValue: [],
                            valuePropName: 'files',
                        })(
                            <Upload
                                name="image"
                                listType="picture-card"
                                className="image-uploader"
                                showUploadList={false}
                                customRequest={dummyRequest}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                {
                                    single.image ?
                                        <img
                                            src={`https://firebasestorage.googleapis.com/v0/b/${config.storageBucket}/o/restaurant_image%2F${single.image}?alt=media`}
                                            alt="restaurant_image" style={{width: '100%'}}/> :
                                        imageUrl ?
                                            <img src={imageUrl} alt="restaurant_image" style={{width: '100%'}}/>
                                            : uploadButton
                                }
                            </Upload>,
                        )}
                    </Form.Item>
                    <Form.Item
                        label="Restaurant description"
                    >
                        {getFieldDecorator('description', {
                            initialValue: single ? single.description : "",
                        })(
                            <TextArea
                                placeholder="Restaurant description"
                            />,
                        )}
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item>
                <Button type="primary" disabled={loading} loading={loading} htmlType="submit" block>
                    {single ? "Update" : "Save"} Restaurant
                </Button>
                <Button type="danger" block
                        onClick={() => {
                            props.form.resetFields();
                            props.onCancel();
                        }}
                >
                    Cancel
                </Button>
            </Form.Item>
        </Form>
    );
};

const AddRestaurant = Form.create({name: 'addrestaurantform'})(AddRestaurantForm);

export default AddRestaurant;
