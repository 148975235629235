import React, {useContext, useEffect, useState} from 'react';
import {
    Form,
    Input,
    Icon,
    Select,
    Button, Row, Col, Upload, message, DatePicker, Spin, Modal, Switch
} from 'antd';
import axios from "axios"
import firebase from '../../firebase'
import moment from "moment";
const { Option } = Select;

const EditDeliveryPeopleWrapper = (props) => {
    const { getFieldDecorator } = props.form;
    const [countryCode, setCountryCode] = useState("");
    const [confirmDirty, setconfirmDirty] = useState(false);
    const [loadingUploader, setLoadingUploader] = useState(false);
    const [imageUrl, setImageUrl] = useState(props.single.profile_image_url);
    const [ppMimeType, setppMimeType] = useState("image/jpeg");
    const [imageChanged, setImageChanged] = useState(false);
    const [modelYear, setModelYear] = useState(props.single ? moment(props.single.modelYear) : modelYear);
    const [isOpen, setIsopen] = useState(false);
    const [savingDriver, setSavingDriver] = useState(false);

    const [single, setSingle] = useState(props.single);

    // get initials
    useEffect(() => {
        getGeoInfo();
    });

    // get user country code automatically
    const getGeoInfo = () => {
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            if (data.country_calling_code) {
                setCountryCode(data.country_calling_code);
            }
            else {
                setCountryCode("+1");
            }
        }).catch((error) => {
            console.log(error);
            setCountryCode("+1");
        });

    };

    // handle custom field blur for password validation
    const handleConfirmBlur = e => {
        const { value } = e.target;
        setconfirmDirty(confirmDirty || !!value);
    };

    // compare tot the first typed password
    const validatePhone = (rule, value, callback) => {
        const { form } = props;
        var phoneno = /^([0-9_-]){4,11}$/;
        if (value && !value.match(phoneno)) {
            callback('Please type a valid password!');
        } else {
            callback();
        }
    };

    // compare tot the first typed password
    const compareToFirstPassword = (rule, value, callback) => {
        const { form } = props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    // validate to the next confirm password
    const validateToNextPassword = (rule, value, callback) => {
        const { form } = props;
        if (value && confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    // call before upload profile image
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    // get base64 image on select image
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', (evt) => {
            callback(reader.result, img.type)
        });
        reader.readAsDataURL(img);
    }

    // create base64 on change uploader
    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoadingUploader(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl, type) => {
                setImageUrl(imageUrl);
                let mimeType = type === "image/jpeg" ? ".jpg": ".png";
                setppMimeType(mimeType);
                setLoadingUploader(true);
                setImageChanged(true);
            });
        }
    };

    // handle form submit
    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setSavingDriver(true);
                values.modelYear = moment(modelYear).format("YYYY");
                values.usertype = "Driver";

                let user = single;
                if (imageChanged) {
                    var uploadTask = firebase.storage.child('user_profile_image/' + user.key + ppMimeType).putString(imageUrl, 'data_url');

                    uploadTask.on('state_changed', function (snapshot) {
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                        if (progress === 100) {
                            console.log('upload', 'uploaddone');
                        }
                    }, function (error) {
                        console.log('Filed uploading profile image');
                    }, function () {
                        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                            values.profile_image_url = downloadURL;
                            values.status = 0;
                            values.phoneExt = countryCode;
                            firebase.db.ref().child('users/'+user.key).set(values);
                            setSavingDriver(false);
                            props.form.resetFields();
                            setLoadingUploader(false);
                            Modal.success({
                                content: 'Delivery people successfully updated.',
                                onOk: () => {
                                    props.onSuccess();
                                }
                            });
                        });
                    });
                }
                else {
                    values.profile_image_url = imageUrl;
                    values.status = 0;
                    values.phoneExt = countryCode;
                    firebase.db.ref().child('users/'+user.key).set(values);
                    setSavingDriver(false);
                    props.form.resetFields();
                    setLoadingUploader(false);
                    Modal.success({
                        content: 'Delivery people successfully updated.',
                        onOk: () => {
                            props.onSuccess();
                        }
                    });
                }
            }
        });
    };

    // create upload btn
    const uploadButton = (
        <div>
            <Icon type={loadingUploader ? 'loading' : 'plus'} />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    // basic form label layout
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    // special form label layout
    const formItemcs = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    }

    return (
        <Form labelAlign="left" {...formItemLayout} onSubmit={handleSubmit}>

            <div className="form-inner-container">
                <div className="card">
                    <div className="card-header">
                        <h5>Add Driver</h5>
                    </div>
                    <div className="card-block">
                        <Row gutter={20}>
                            <Col xs={{span: 24}} lg={{span: 12}}>
                                <Row gutter={15}>
                                    <Col xs={{span: 24}} lg={{span: 12}}>
                                        <Form.Item label="First Name" {...formItemcs}>
                                            {getFieldDecorator('firstname', {
                                                initialValue: single ? single.firstname : '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input first name!',
                                                    },
                                                ],
                                            })(<Input placeholder="First Name"/>)}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{span: 24}} lg={{span: 12}}>
                                        <Form.Item label="Last Name" {...formItemcs}>
                                            {getFieldDecorator('lastname', {
                                                initialValue: single ? single.lastname : '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input last name!',
                                                    },
                                                ],
                                            })(<Input placeholder="Last Name"/>)}
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item label="E-mail">
                                    {getFieldDecorator('email', {
                                        initialValue: single ? single.email : '',
                                        rules: [
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                            {
                                                required: true,
                                                message: 'Please input E-mail!',
                                            },
                                        ],
                                    })(<Input placeholder="E-mail"/>)}
                                </Form.Item>


                                <Form.Item label="Phone Number">
                                    {getFieldDecorator('phone', {
                                        initialValue: single ? single.phone : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input phone number!'
                                            },
                                            {
                                                validator: validatePhone,
                                            }
                                        ],
                                    })(<Input max={10} min={4} addonBefore={countryCode} placeholder="Phone Number" style={{ width: '100%' }} />)}
                                </Form.Item>

                                <Form.Item label="Driver Status">
                                    {getFieldDecorator('approved', {
                                        valuePropName: 'checked',
                                        initialValue: single ? single.approved : true
                                    })(
                                        <Switch checkedChildren="Approved" unCheckedChildren="Not Approved"/>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 12}}>
                                <Row>
                                    <Form.Item label="Street Address">
                                        {getFieldDecorator('address', {
                                            initialValue: single ? single.address : '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input street address!',
                                                },
                                            ],
                                        })(<Input placeholder="Street Address"/>)}
                                    </Form.Item>
                                </Row>
                                <Row gutter={15}>
                                    <Col xs={{span: 24}} lg={{span: 12}}>
                                        <Form.Item label="State" {...formItemcs}>
                                            {getFieldDecorator('state', {
                                                initialValue: single ? single.state : '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input state!',
                                                    },
                                                ],
                                            })(<Input placeholder="State"/>)}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{span: 24}} lg={{span: 12}}>
                                        <Form.Item label="Zip Code" {...formItemcs}>
                                            {getFieldDecorator('zipcode', {
                                                initialValue: single ? single.zipcode : '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input zip code!',
                                                    },
                                                ],
                                            })(<Input placeholder="Zip Code"/>)}
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item label="Profile Image:">
                                    <Upload
                                        method="get"
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        beforeUpload={beforeUpload}
                                        onChange={handleChange}
                                    >
                                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h5>Add Driver Vehicle Details</h5>
                    </div>
                    <div className="card-block">
                        <Row gutter={20}>
                            <Col xs={{span: 24}} lg={{span: 12}}>
                                <Form.Item label="Vehicle Type">
                                    {getFieldDecorator('vehicleType', {
                                        initialValue: single ? single.vehicleType : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input vehicle type!',
                                            },
                                        ],
                                    })(<Input placeholder="Vehicle Type"/>)}
                                </Form.Item>

                                <Form.Item label="Vehicle Company">
                                    {getFieldDecorator('vehicleCompany', {
                                        initialValue: single ? single.vehicleCompany : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input vehicle company!',
                                            },
                                        ],
                                    })(<Input placeholder="Vehicle Company"/>)}
                                </Form.Item>

                                <Form.Item label="Model Name">
                                    {getFieldDecorator('modelName', {
                                        initialValue: single ? single.modelName : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input vehicle model name!',
                                            },
                                        ],
                                    })(<Input placeholder="Model Name"/>)}
                                </Form.Item>

                            </Col>
                            <Col xs={{span: 24}} lg={{span: 12}}>

                                <Form.Item label="Model Year">
                                    {getFieldDecorator('modelYear', {
                                        initialValue: modelYear,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input vehicle model year!',
                                            },
                                        ],
                                    })(<DatePicker
                                        mode="year"
                                        open={isOpen}
                                        onPanelChange={(v) => {
                                            setModelYear(v);
                                            setIsopen(false)
                                        }}
                                        onOpenChange={(status) => {
                                            if(status){
                                                setIsopen(true)
                                            } else {
                                                setIsopen(false)
                                            }
                                        }}
                                        format="YYYY"/>)}
                                </Form.Item>

                                <Form.Item label="Plat No">
                                    {getFieldDecorator('platNo', {
                                        initialValue: single ? single.platNo : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input vehicle Plat No!',
                                            },
                                        ],
                                    })(<Input placeholder="Plat No"/>)}
                                </Form.Item>

                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h5>Add Bank Details</h5>
                    </div>
                    <div className="card-block">
                        <Row gutter={20}>
                            <Col xs={{span: 24}} lg={{span: 12}}>
                                <Form.Item label="Bank Name" {...formItemcs}>
                                    {getFieldDecorator('bankName', {
                                        initialValue: single ? single.bankName : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input bank name!',
                                            },
                                        ],
                                    })(<Input placeholder="Bank Name"/>)}
                                </Form.Item>

                                <Form.Item label="Account Number" {...formItemcs}>
                                    {getFieldDecorator('accountNumber', {
                                        initialValue: single ? single.accountNumber : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input account number!',
                                            },
                                        ],
                                    })(<Input placeholder="Account Number"/>)}
                                </Form.Item>

                                <Form.Item label="Payment Email Address" {...formItemcs}>
                                    {getFieldDecorator('paymentEmailAddress', {
                                        initialValue: single ? single.paymentEmailAddress : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input payment email address!',
                                            },
                                        ],
                                    })(<Input placeholder="Payment Email Address"/>)}
                                </Form.Item>

                            </Col>
                            <Col xs={{span: 24}} lg={{span: 12}}>

                                <Form.Item label="Bank Location(City Name)" {...formItemcs}>
                                    {getFieldDecorator('bankLocation', {
                                        initialValue: single ? single.bankLocation : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input bank location!',
                                            },
                                        ],
                                    })(<Input placeholder="Bank Location(City Name)"/>)}
                                </Form.Item>

                                <Form.Item label="Account Holder Name" {...formItemcs}>
                                    {getFieldDecorator('accountHolderName', {
                                        initialValue: single ? single.accountHolderName : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input account holder name!',
                                            },
                                        ],
                                    })(<Input placeholder="Account Holder Name"/>)}
                                </Form.Item>

                                <Form.Item label="Routing Number" {...formItemcs}>
                                    {getFieldDecorator('routingNumber', {
                                        initialValue: single ? single.routingNumber : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input routing number!',
                                            },
                                        ],
                                    })(<Input placeholder="Routing Number"/>)}
                                </Form.Item>

                            </Col>
                        </Row>
                    </div>
                </div>

            </div>

            <Row type="flex" justify="center">
                <Form.Item>
                    <Button disabled={savingDriver} loading={savingDriver} size="large" type="primary" htmlType="submit">
                        {savingDriver ? "Saving please wait . . " : "Save"}
                    </Button>
                </Form.Item>
            </Row>
        </Form>
    );
};

const EditDeliveryPeople = Form.create({ name: 'add-driver' })(EditDeliveryPeopleWrapper);

export default EditDeliveryPeople;
