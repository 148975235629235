import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Form, Icon, Input, Modal, Table} from "antd";
import AdminLayout from "../../components/AdminLayout";
import firebase from "../../firebase";
import AddRestaurant from "./AddRestaurant";

const Search = Input.Search;

const AddRestaurantSpecialityForm = (props) => {
    //set loading
    const [loading, setLoading] = useState(false);
    // set single speciality if action is update speciality
    const [single, setSingle] = useState(props.single && props.single);

    // submit the form
    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            // check if the form validated
            if (!err) {
                // check if action is update or not
                if (single) {
                    updateSpeciality(single.key, values);
                } else {
                    addNewSpeciality(values);
                }
            }
        });
    };

    // create a new speciality
    const addNewSpeciality = (values) => {
        firebase.createSpeciality({
            name: values.name,
        }, (addedresp) => {
            if (addedresp.success) {
                Modal.success({
                    title: "Success!",
                    content: "Speciality successfully added."
                });
                props.form.resetFields();
                setLoading(false);
                props.onSuccess();
            } else {
                Modal.error({
                    title: "Sorry!",
                    content: addedresp.msg
                });
                setLoading(false);
            }
        })
    }

    // update speciality
    const updateSpeciality = (id, values) => {
        firebase.updateSpeciality(id, {
            name: values.name,
        }, (addedresp) => {
            if (addedresp.success) {
                Modal.success({
                    title: "Success!",
                    content: "Speciality successfully updated."
                });
                props.form.resetFields();
                setLoading(false);
                props.onSuccess();
            } else {
                Modal.error({
                    title: "Sorry!",
                    content: addedresp.msg
                });
                setLoading(false);
            }
        })
    }

    const {getFieldDecorator} = props.form;

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item
                label="Speciality Name"
            >
                {getFieldDecorator('name', {
                    initialValue: single ? single.name : "",
                    rules: [{required: true, message: 'Please input speciality name!'}],
                })(
                    <Input
                        placeholder="Speciality Name"
                    />,
                )}
            </Form.Item>

            <Form.Item>
                <Button type="primary" disabled={loading} loading={loading} htmlType="submit" block>
                    {single ? "Update" : "Save"} Speciality
                </Button>
                <Button type="danger" block
                        onClick={() => {
                            props.form.resetFields();
                            props.onCancel();
                        }}
                >
                    Cancel
                </Button>
            </Form.Item>
        </Form>
    )
}
const AddRestaurantSpeciality = Form.create({name: 'addrestaurantspecialityform'})(AddRestaurantSpecialityForm);

const RestaurantSpeciality = (props) => {
    const [loadingSpecialities, setLoadingSpecialities] = useState(false);
    const [addSpecialityModal, setSpecialityModal] = useState(false);
    const [specialities, setSpecialities] = useState([]);
    const [filteredSpecialities, setFilteredSpecialities] = useState([]);
    const [single, setSingle] = useState(false);

    // load all specialities
    useEffect(() => {
        setLoadingSpecialities(true);
        // get specialities
        firebase.getSpecialities((resp) => {
            setSpecialities(resp);
            setFilteredSpecialities(resp);
            setLoadingSpecialities(false);
        });
    }, [])

    // initiate column names for specialities
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button size="small" type="primary" style={{marginRight: "5px"}} onClick={() => {
                        setSingle(record);
                        setSpecialityModal(true);
                    }} icon="edit"/>
                    <Button size="small" type="danger"
                            onClick={() => {
                                // ask user for confirmation to delete
                                Modal.confirm({
                                    title: 'Do you want to delete these speciality?',
                                    content: 'Once you delete this speciality, you cannot retrieve later.',
                                    onOk() {
                                        return new firebase.deleteSpecialities(record.key, (delresp) => {
                                            Modal.success({
                                                title: "Success!",
                                                content: "Speciality successfully deleted."
                                            })
                                        });
                                    },
                                    onCancel() {},
                                })
                            }}
                            icon="delete"
                    />

                </>
            ),
        }
    ];

    return (
        <AdminLayout {...props}>
            <div>
                <div className="page-actions">
                    <Button type="primary" style={{marginBottom: 10}} onClick={() => {
                        setSpecialityModal(true);
                    }}>
                        Add Restaurant Speciality
                    </Button>
                </div>
                <div className="card">
                    <div className="card-header">
                        <div>
                            <h2>Restaurant Specialities</h2>
                        </div>
                        <div>
                            <Search
                                placeholder="Search specialitie(s) by name"
                                onChange={e => {
                                    const thfilteredSpecialities = specialities.filter(({ name }) => {
                                        name = name.toLowerCase();
                                        return name.includes(e.target.value.toLowerCase());
                                    });
                                    setFilteredSpecialities(thfilteredSpecialities);
                                }}
                                onSearch={value => {
                                    const thfilteredSpecialities = specialities.filter(({ name }) => {
                                        name = name.toLowerCase();
                                        return name.includes(value.toLowerCase());
                                    });
                                    setFilteredSpecialities(thfilteredSpecialities);
                                }}
                                style={{ width: 300 }}
                                allowClear={true}
                            />
                        </div>
                    </div>
                    <div className="card-block">
                        <Table
                            size="small"
                            loading={loadingSpecialities}
                            columns={columns}
                            dataSource={filteredSpecialities}
                        />
                    </div>
                </div>
            </div>
            <Modal
                title={`${single ? "Update" : "Create"} Restaurant Speciality`}
                visible={addSpecialityModal}
                onCancel={() => {
                    setSpecialityModal(false);
                }}
                maskClosable={false}
                closable={false}
                footer={null}
            >
                <AddRestaurantSpeciality key={single ? single.key : "AddRestaurant"} single={single} onSuccess={() => {
                    setSpecialityModal(false);
                    setSingle(false);
                }} onCancel={() => {
                    setSpecialityModal(false);
                    setSingle(false);
                }} />
            </Modal>
        </AdminLayout>
    );
};

export default RestaurantSpeciality;
