import React from 'react';

import Core from "./core";

import './App.css';

class App extends React.Component {
  render() {
    return (
      <Core />
    )
  }
}

export default App;
