import React, { useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom'

import firebase from '../../firebase'

import {
  Button,
  Input,
  Modal,
  Table,
  Icon,
  Menu,
  Dropdown
} from "antd";

import Highlighter from 'react-highlight-words';

import AdminLayout from "../../components/AdminLayout";
import AddRestaurant from "./AddRestaurant";

const { Search } = Input;
const { confirm } = Modal;

const Restaurant = (props) => {
  let searchInput;
  const [loadingRestaurant, setloadingRestaurant] = useState(false);
  const [restaurants_data, setRestaurants] = useState([]);
  const [filtredRestaurants, setFiltredRestaurants] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [currentKey, setCurrentKey] = useState("");

  // add restaurant
  const [addRestaurantModal, setAddRestaurantModal] = useState(false)
  const [single, setSingle] = useState(false)

  // get all essentials
  useEffect(() => {
    getAllRestaurant();
  }, []);

  // load all restaurants
  const getAllRestaurant = () => {
    setloadingRestaurant(true);
    firebase.getAllRestaurants((data) => {
      setRestaurants(data);
      setFiltredRestaurants(data);
      setloadingRestaurant(false);
    })
  }

  // delete restaurant by id
  const deleteRestaurant = (id) => {
    confirm({
      title: 'Do you want to delete this restaurant?',
      content: "After deleted you cannot retrieve once you've deleted the record.",
      onOk() {
        let driverRef = firebase.db.ref('restaurant/' + id);
        driverRef.remove().then(() => {
          Modal.success({
            content: 'Restaurant successfully deleted!'
          });
        });
      },
      onCancel() {
      },
    });
  }

  // block the restaurant
  const blockRestaurant = (id, blockedStatus) => {
    var blockedStatusDesc = "";
    if (blockedStatus === 0) {
      blockedStatusDesc = "unblock";
    } else if (blockedStatus === 1) {
      blockedStatusDesc = "blocked";
    }

    confirm({
      title: 'Do you want to ' + blockedStatusDesc + 'this restaurant?',
      content: '',
      onOk() {
        let driverRef = firebase.db.ref('restaurant/' + id + '/blocked').set(blockedStatus);
        driverRef.then(() => {
          let msgPart = blockedStatus ? "blocked" : "unblocked";
          Modal.success({
            content: (
              "Restaurant has been " + msgPart
            )
          });
        });
      },
      onCancel() {
      },
    });

  }

  //0 Pending
  //1 Approved
  //2 Rejected

  //  change restaurant status
  const changeCurrentStatusRestaurant = (id, status) => {
    // make restaurant status condition
    var statusDesc = "";
    if (status === 0) {
      statusDesc = "pending";
    } else if (status === 1) {
      statusDesc = "approved";
    } else if (status === 2) {
      statusDesc = "closed";
    }

    confirm({
      title: 'Do you want to change the restaurant status to "' + capitalFLatter(statusDesc) + '" ?',
      content: '',
      onOk() {
        // change the restaurant status
        let restoRef = firebase.db.ref('restaurant/' + id + '/current_status').set(statusDesc);
        restoRef.then(() => {
          Modal.success({
            content: (
              "Restaurant has been changed to " + statusDesc
            )
          });
        });
      },
      onCancel() {
      },
    });
  }

  // set current restaurant status
  const setCurrentRestaurantKey = (id) => {
    setCurrentKey(id);
  }

  // change online/offline status of a retaurant
  const changeStatus = (id, status) => {
    var statusDesc = "";
    // check the status type
    if (status === 0) {
      statusDesc = "Offline";
    } else if (status === 1) {
      statusDesc = "Online";
    }

    confirm({
      title: 'Do you want to change the status to ' + statusDesc + ' ?',
      content: '',
      onOk() {
        // change restaurant status to online/offline by ID
        let restoRef = firebase.db.ref('restaurant/' + id + '/status').set(status);
        restoRef.then(() => {
          Modal.success({
            content: (
              "Restaurant has been changed to " + statusDesc
            )
          });
        });
      },
      onCancel() {
      },
    });
  }

  // handle the action menu onclick
  function handleMenuClick(e, record) {
    switch (parseInt(e.key)) {
      case 1:
        setSingle(record);
        setAddRestaurantModal(true);
        break;
      case 2:
        deleteRestaurant(currentKey);
        break;
      case 3:
        changeCurrentStatusRestaurant(currentKey, 0);
        break;
      case 4:
        changeCurrentStatusRestaurant(currentKey, 1);
        break;
      case 5:
        changeCurrentStatusRestaurant(currentKey, 2);
        break;
      case 6:
        if (parseInt(record.blocked) === 0) {
          blockRestaurant(currentKey, 1);
        } else {
          blockRestaurant(currentKey, 0);
        }
        break;
      default:
      // do nothing
    }
  }

  // restaurant table action menu
  const menu = (text, record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        <Menu.Item key="1">
          <Icon type="edit" />
                    Edit
                </Menu.Item>
        <Menu.Item key="2">
          <Icon type="delete" />
                    Delete
                </Menu.Item>
        { record.current_status !== "pending" ?
          <Menu.Item key="3">
            <Icon type="exclamation-circle" />
                    Set status as Pending
                </Menu.Item>
          : <></>}
        { record.current_status !== "approved" ?
          <Menu.Item key="4">
            <Icon type="check" />
                    Set status as Approved
                </Menu.Item>
          : <></>}
        { record.current_status !== "closed" ?
          <Menu.Item key="5">
            <Icon type="close" />
                    Set status as Closed
                </Menu.Item>
          : <></>}
        <Menu.Item key="6">
          <Icon type={parseInt(record.blocked) === 1 ? "check-circle" : "stop"} />
          {parseInt(record.blocked) === 1 ? "Unblock" : "Block"}
        </Menu.Item>
      </Menu>
    )
  };

  // create the search callback for search
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
                </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
                </Button>
      </div>
    ),
    // create the custom search icon
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    // highlight the texts after serach
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text}
        />
      ) : (
        text
      ),
  });

  // handle the search input
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  // handle the reset search
  const handleReset = clearFilters => {
    clearFilters();
    setSearchText("");
  };

  // filter text to make first letter capital
  const capitalFLatter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // initialize the table columns
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Ratings',
      dataIndex: 'ratings',
      key: 'ratings',
    },
    {
      title: 'Speciality',
      dataIndex: 'foodspeciality',
      key: 'foodspeciality',
    },
    // update the restaurant status
    {
      title: 'Online/Offline',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (record.status && record.status === 1 ?
        <Button type="primary" size="small" onClick={() => changeStatus(record.key, 0)}>Online</Button> :
        <Button type="danger" size="small" onClick={() => changeStatus(record.key, 1)}>Offline</Button>)
    },
    {
      title: 'Status',
      dataIndex: 'current_status',
      key: 'current_status',
      render: (text) => {
        return capitalFLatter(text ? text : "pending")
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <Dropdown overlay={menu(text, record)} trigger={['click']}>
            <Button
              type="dashed"
              size="small"
              onClick={() => setCurrentRestaurantKey(record.key)}
            >...</Button>
          </Dropdown>
        )
      }
    },
  ];

  return (
    <AdminLayout {...props}>
      <div>
        <div className="page-actions">
          <Button type="primary" style={{ marginBottom: 10 }} onClick={() => {
            setAddRestaurantModal(true);
          }}>
            <span className="nav-text">Add Restaurant</span>
          </Button>
        </div>
        <div className="card">
          <div className="card-header">
            <div>
              <h2>Restaurants</h2>
            </div>
            <div>
              <Search
                placeholder="Search restaurant(s) by name"
                onChange={e => {
                  const thfilteredMenus = restaurants_data.filter(({ name }) => {
                    name = name.toLowerCase();
                    return name.includes(e.target.value.toLowerCase());
                  });
                  setFiltredRestaurants(thfilteredMenus);
                }}
                onSearch={value => {
                  const thfilteredMenus = restaurants_data.filter(({ name }) => {
                    name = name.toLowerCase();
                    return name.includes(value.toLowerCase());
                  });
                  setFiltredRestaurants(thfilteredMenus);
                }}
                style={{ width: 300 }}
                allowClear={true}
              />
            </div>
          </div>
          <div className="card-block">
            <Table
              size="small"
              loading={loadingRestaurant}
              columns={columns}
              dataSource={filtredRestaurants}
            />
          </div>
        </div>
      </div>
      <Modal
        width="70%"
        title="Create New Restaurant"
        visible={addRestaurantModal}
        onCancel={() => {
          setAddRestaurantModal(false);
        }}
        maskClosable={false}
        closable={false}
        footer={null}
      >
        <AddRestaurant key={single ? single.key : "addrestaurant"} single={single} onSuccess={() => {
          setAddRestaurantModal(false);
          setSingle(false);
        }} onCancel={() => {
          setAddRestaurantModal(false);
          setSingle(false);
        }} />
      </Modal>
    </AdminLayout>
  );

};

export default withRouter(Restaurant);
