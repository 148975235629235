import React, { useEffect, useState } from 'react';
import firebase from "../../firebase";
import { Button, Form, Row, Col, Input, Modal } from "antd";
const CusineFormWrapper = (props) => {
    const [selectedCuisine, setSelectedCuisine] = useState(false);
    const [savingCuisine, setSavingCuisine] = useState(false);
    const { getFieldDecorator } = props.form;

    useEffect(() => {
        if (props.selectedCuisine){
            setSelectedCuisine(props.selectedCuisine);
        }
    });
    
    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setSavingCuisine(true);
                firebase.createCuisine(values, resp => {
                    if (resp.success) {
                        Modal.success({
                            title: 'Adding Cuisine',
                            content: "Cuisine has been successfully added!",
                        });
                        setSavingCuisine(false);
                        props.onSuccess();
                        props.form.resetFields();
                    } else {
                        Modal.error({
                            title: 'Adding Cuisine failed',
                            content: resp.message,
                        });
                    }
                })
            }
        });
    };

    const handleUpdateSubmit = e => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setSavingCuisine(true);
                firebase.updateCuisine(selectedCuisine.id, values, resp => {
                    if (resp.success) {
                        Modal.success({
                            title: 'Updating Cuisine',
                            content: "Cuisine has been successfully updated!",
                        });
                        setSavingCuisine(false);
                        props.onSuccess();
                        props.form.resetFields();
                    } else {
                        Modal.error({
                            title: 'Updating Cuisine failed',
                            content: resp.message,
                        });
                    }
                })
            }
        });
    };

    const formItemcs = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    }
    return (
        <Form onSubmit={selectedCuisine ? handleUpdateSubmit : handleSubmit}>
            <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                    <Form.Item label="Name" {...formItemcs}>
                        {getFieldDecorator('name', {
                            initialValue: selectedCuisine ? selectedCuisine.name : '',
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input name!',
                                },
                            ],
                        })(<Input placeholder="Name" />)}
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                    <Form.Item label="Description" {...formItemcs}>
                        {getFieldDecorator('description', {
                            initialValue: selectedCuisine ? selectedCuisine.description : '',
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                        })(<Input.TextArea placeholder="Description" />)}
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <Button type="primary" htmlType="submit" block disabled={savingCuisine} loading={savingCuisine} >
                    {savingCuisine ? "Saving please wait . . " : selectedCuisine ? "Update":"Save"} Cuisine
                </Button>
            </Form.Item>
        </Form>
    );
};

const CusineForm = Form.create({ name: "cuisine_form" })(CusineFormWrapper);
export default CusineForm;
