const config = {
    hasSidebar: true,
    siteName: 'BeachEatz',
    description: 'BeachEatz',
    keywords: 'BeachEatz',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    copyright: '@ Copyright BeachEatz ' + (new Date()).getFullYear(),
}

export default config
