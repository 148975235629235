import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom'
import firebase from '../../firebase'
import AdminLayout from "../../components/AdminLayout";
import Form from "./form";

import Highlighter from 'react-highlight-words';
import {
    Table, Card, Button, Modal, Icon, Input
} from 'antd';

const Cuisines = (props) => {
    let searchInput;
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [isVisibleForm, setVisibleForm] = useState(false);

    const [selectedCuisine, setSelectedCuisine] = useState(false);
    const [cuisines, setCuisines] = useState([]);
    const [loadingCuisines, setLoadingCuisines] = useState(false);

    useEffect(() => {
        getCuisines();
    }, []);

    const getCuisines = () => {
        setLoadingCuisines(true);
        firebase.getCuisines((data) => {
            setCuisines(data);
            setLoadingCuisines(false);
        })
    }


    const deleteCuisine = (cuisineID) => {
        Modal.confirm({
            title: 'Are you sure you want to delete the selected cuisine?',
            content: 'Cannot be retrieved after deletion',
            icon: <Icon type="question-circle" />,
            okText: 'Yes',
            onOk() {
                firebase.deleteCuisine(cuisineID, resp => {
                    if (resp.success) {
                        Modal.success({
                            title: 'Deleting Cuisine',
                            content: 'Cuisine has been successfully deleted!'
                        })
                    } else {
                        Modal.error({
                            title: 'Deleting Cuisine Failed',
                            content: resp.message,
                        });
                    }
                });
            },
            onCancel() { },
        });
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text}
                />
            ) : (
                    text
                ),
    });

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            ...getColumnSearchProps('key'),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            ...getColumnSearchProps('customer_details'),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button type="primary" style={{marginRight:"5px"}}  onClick={() => {setSelectedCuisine(record); setVisibleForm(true);}}>
                        Edit
                    </Button>
                    <Button type="danger"  onClick={() => deleteCuisine(record.id)} >
                        Delete
                    </Button>

                </>
            ),
        }
    ];

    return (
        <AdminLayout {...props}>
            <div>
                <Button type="primary" style={{ marginBottom: 10 }} onClick={() => {
                    setVisibleForm(true);
                }}>

                    <Icon type="user-add" />
                    <span className="nav-text"> Add Cuisine</span>

                </Button>
                <Card title="Cuisines List">
                    <Table
                        size="small"
                        loading={loadingCuisines}
                        columns={columns}
                        dataSource={cuisines}
                    />
                </Card>
            </div>
            <Modal
                title="Create Cuisine"
                visible={isVisibleForm}
                onCancel={() => {
                    setVisibleForm(false);
                }}
                footer={null}
            >
                <Form selectedCuisine={selectedCuisine} onSuccess={() => {
                    setVisibleForm(false);
                }} />
            </Modal>
        </AdminLayout>
    );

}
export default withRouter(Cuisines);
