import React from 'react';

import { Spin } from "antd";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import firebase from './firebase'

import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";

import Dashboard from "./pages/Dashboard";
import Users from "./pages/user/index";
import AddUser from "./pages/user/add";
import DeliveryPeople from "./pages/drivers/index";
import AddDeliveryPeople from "./pages/drivers/add";
import RestaurantDisplay from "./pages/restaurant/index";
import Orders from "./pages/order/index";
import Cuisines from './pages/cuisine/index';
import Menus from "./pages/menu";
import Coupons from "./pages/coupons";
import MenuCategories from "./pages/menu/menu_categories";
import RestaurantSpeciality from "./pages/restaurant/restaurant-speciality";

class Core extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firebaseInitialized: false
    };
  }

  componentDidMount() {
    firebase.isInitialized().then(val => {
      this.setState({
        firebaseInitialized: val
      });
    });
  }

  render() {
    return this.state.firebaseInitialized !== false ? (
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/drivers" component={DeliveryPeople} />
          <Route exact path="/add-driver" component={AddDeliveryPeople} />
          <Route exact path="/orders" component={Orders} />
          <Route exact path="/cuisines" component={Cuisines} />
          <Route exact path="/menus" component={Menus} />
          <Route exact path="/menu-categories" component={MenuCategories} />
          <Route exact path="/coupons" component={Coupons} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/user/add" component={AddUser} />
          <Route exact path="/restaurants" component={RestaurantDisplay} />
          <Route exact path="/restaurant-specialities" component={RestaurantSpeciality} />
          <Route path="*">
            <Redirect to='/dashboard' />
          </Route>
        </Switch>
      </Router>
    ) :
      <div id="loader">
        <Spin />
      </div>
  }
}

export default Core
