import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Form, Icon, Modal, Table, Input, InputNumber, DatePicker} from "antd";
import AdminLayout from "../../components/AdminLayout";
import firebase from "../../firebase";
import moment from "moment";

const CouponsWrapper = (props) => {
    const [loadingCoupons, setLoadingCoupons] = useState(false);
    const [addCouponModal, setAddCouponModal] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [single, setSingle] = useState(false);

    useEffect(() => {
        setLoadingCoupons(true);
        firebase.getCoupons((resp) => {
            setCoupons(resp);
            setLoadingCoupons(false);
        })
    }, [])

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                values.activation_date = moment(values.activation_date).format("YYYY-MM-DD");
                values.expiration_date = moment(values.expiration_date).format("YYYY-MM-DD");
                if (single){
                    firebase.updateCoupon(single.id, values, (addedresp) => {
                        if (addedresp.success) {
                            Modal.success({
                                title: "Success!",
                                content: "Coupon successfully updated."
                            });
                            props.form.resetFields();
                            setAddCouponModal(false);
                        } else {
                            Modal.error({
                                title: "Sorry!",
                                content: addedresp.msg
                            });
                        }
                        setSingle(false);
                    });
                }
                else {
                    firebase.createCoupon(values, (addedresp) => {
                        if (addedresp.success) {
                            Modal.success({
                                title: "Success!",
                                content: "Coupon successfully added."
                            });
                            props.form.resetFields();
                            setAddCouponModal(false);
                        } else {
                            Modal.error({
                                title: "Sorry!",
                                content: addedresp.msg
                            });
                        }
                        setSingle(false);
                    });
                }
            }
        });
    };

    const randomString = (length) => {
        let chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    const { getFieldDecorator } = props.form;

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Discount in $',
            dataIndex: 'discount_in_dollar',
            key: 'discount_in_dollar',
        },
        {
            title: 'Discount in %',
            dataIndex: 'discount_in_percentage',
            key: 'discount_in_percentage',
        },
        {
            title: 'Expiration date',
            dataIndex: 'expiration_date',
            key: 'expiration_date',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button size="small" type="primary" style={{marginRight: "5px"}} onClick={() => {
                        setSingle(record);
                        setAddCouponModal(true);
                    }} icon="edit"/>
                    <Button size="small" type="danger"
                            onClick={() => {
                                Modal.confirm({
                                    title: 'Do you want to delete this coupon?',
                                    content: 'Once you delete this coupon, you cannot retrieve later.',
                                    onOk() {
                                        return new firebase.deleteCoupon(record.key, (delresp) => {
                                            Modal.success({
                                                title: "Success!",
                                                content: "Coupon successfully deleted."
                                            });
                                        });
                                    },
                                    onCancel() {},
                                })
                            }}
                            icon="delete"
                    />

                </>
            ),
        }
    ];

    return (
        <AdminLayout {...props}>
            <div>
                <div className="page-actions">
                    <Button type="primary" style={{marginBottom: 10}} onClick={() => {
                        setAddCouponModal(true);
                    }}>
                        Add Coupon
                    </Button>
                </div>
                <Card title="Coupons">
                    <Table
                        size="small"
                        loading={loadingCoupons}
                        columns={columns}
                        dataSource={coupons}
                    />
                </Card>
            </div>
            <Modal
                title={`${single ? "Update" : "Create"} Coupon`}
                visible={addCouponModal}
                onCancel={() => {
                    setAddCouponModal(false);
                }}
                maskClosable={false}
                closable={false}
                footer={null}
            >
                <Form onSubmit={handleSubmit}>
                    <Form.Item
                        label="Name"
                    >
                        {getFieldDecorator('name', {
                            initialValue: single ? single.name : "",
                            rules: [{ required: true, message: 'Please input coupon name!' }],
                        })(
                            <Input
                                placeholder="Name"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item
                        label="Code"
                    >
                        {getFieldDecorator('code', {
                            initialValue: single ? single.code : "",
                            rules: [{ required: true, message: 'Please input code!' }],
                        })(
                            <Input
                                placeholder="Code"
                                addonAfter={<Button size="small" icon="retweet" onClick={() => {
                                    props.form.setFieldsValue({
                                        code: randomString(20)
                                    });
                                }}/>}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item
                        label="Discount ($)"
                    >
                        {getFieldDecorator('discount_in_dollar', {
                            initialValue: single ? single.discount_in_dollar : 0.00,
                        })(
                            <InputNumber
                                style={{width: '100%'}}
                                min={0.00}
                                step={0.01}
                                placeholder="Discount in $"
                            />,
                        )}
                    </Form.Item>

                    <Form.Item
                        label="Discount (%)"
                    >
                        {getFieldDecorator('discount_in_percentage', {
                            initialValue: single ? single.discount_in_percentage : 0.00,
                        })(
                            <InputNumber
                                style={{width: '100%'}}
                                min={0.00}
                                step={0.01}
                                placeholder="Discount in %"
                            />,
                        )}
                    </Form.Item>

                    <Form.Item label="Choose activation date">
                        {getFieldDecorator('activation_date', {
                            initialValue: single ? moment(single.activation_date) : moment(),
                            rules: [{
                                type: 'object',
                                required: true,
                                message: 'Please select activation date!'
                            }]
                        })(<DatePicker />)}
                    </Form.Item>

                    <Form.Item label="Choose expiration date">
                        {getFieldDecorator('expiration_date', {
                            initialValue: single ? moment(single.expiration_date) : moment(),
                            rules: [{
                                type: 'object',
                                required: true,
                                message: 'Please select expiration date!'
                            }]
                        })(<DatePicker />)}
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            {single ? "Update" : "Save"} Coupon
                        </Button>
                        <Button type="danger" block
                                onClick={() => {
                                    setAddCouponModal(false);
                                    setSingle(false);
                                    props.form.resetFields();
                                }}
                        >
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </AdminLayout>
    );
};

const Coupons = Form.create({ name: 'couponsform' })(CouponsWrapper);

export default Coupons;
