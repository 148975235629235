import React from 'react';

import config from "../../config";

import firebase from "../firebase";

import { Layout, Menu, Breadcrumb, Icon, Drawer, Button } from 'antd';

import { Link, withRouter } from "react-router-dom";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class AdminLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMenu: props.match.path.slice(1),
      drawerOn: false,
      userType: '',
      activeUrl: props.match.path.replace(/\//g, '-')
    };

    this.handleDrawerOnClick = this.handleDrawerOnClick.bind(this);
    this.handleMenuOnClick = this.handleMenuOnClick.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    if (!firebase.getCurrentUser()) {
      // not logged in
      this.props.history.replace('/');
    }

    const user = firebase.getCurrentUser();
    firebase.getUserBy(user.uid, (data) => {
      config.CURRENT_USER = data;

      this.setState({
        userType: data.usertype
      });
    });
  }

  handleDrawerOnClick(status) {
    this.setState({
      drawerOn: status
    });
  }

  handleMenuOnClick(key) {
    this.setState({
      activeMenu: key
    });
  }

  handleLogout = async () => {
    try {
        await firebase.logout();

        this.props.history.push('/');
    } catch (e) {
        console.log(e);
    }
  }

  toTitleCase(str) {
    str = str.replace(/-|_/g, " ");

    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      }
    )
  }

  render() {
    return (
      <Layout>
        <Sider
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            background: "#001529",
            left: 0,
          }}
          width={250}
          breakpoint="lg"
          collapsedWidth="0"
        >
          <div className="admin-logo" style={{ height: '132px' }}>
            <img src={require("../../images/logo.png")} alt="logo" style={{ width: '100%' }} />
          </div>

          <Menu theme="dark" className="admin-sidebar-menu" mode="inline" onClick={(e) => this.handleMenuOnClick(e.key)}
            defaultSelectedKeys={[this.state.activeMenu]}
            defaultOpenKeys={['restaurant', 'drivers']}>

            <Menu.Item key="dashboard">
              <Link to="/dashboard">
                <Icon type="appstore-o" />
                <span className="nav-text">Dashboard</span>
              </Link>
            </Menu.Item>

            {this.state.userType === 'Administrator' ?
            <SubMenu
              key="restaurant"
              title={
                <span>
                  <Icon type="rest" />
                  <span>Restaurants</span>
                </span>
              }
            >
              <Menu.Item key="restaurants">
                <Link to="/restaurants">
                  <span className="nav-text">Restaurants</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="restaurant-specialities">
                <Link to="/restaurant-specialities">
                  <span className="nav-text">Restaurant Specialities</span>
                </Link>
              </Menu.Item>
            </SubMenu>
            : <></>}

            {this.state.userType === 'Administrator' ?
            <Menu.Item key="drivers">
              <Link to="/drivers">
                <Icon type="car" />
                <span className="nav-text">Drivers</span>
              </Link>
            </Menu.Item>
             : <></>}

            <Menu.Item key="menus">
              <Link to="/menus">
                <Icon type="container" />
                <span className="nav-text">Menus</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="orders">
              <Link to="/orders">
                <Icon type="ordered-list" />
                <span className="nav-text">Orders</span>
              </Link>
            </Menu.Item>

            {this.state.userType === 'Administrator' ?
              <Menu.Item key="users">
                <Link to="/users">
                  <Icon type="team" />
                  <span className="nav-text">Users</span>
                </Link>
              </Menu.Item>
            : <></>}

            <Menu.Item key="coupons">
              <Link to="/coupons">
                <Icon type="percentage" />
                <span className="nav-text">Coupons</span>
              </Link>
            </Menu.Item>

          </Menu>
        </Sider>
        <Drawer
          drawerStyle={{
            background: "#001529",
          }}
          bodyStyle={{
            padding: 2,
          }}
          title={null}
          placement="left"
          closable={false}
          onClose={() => this.handleDrawerOnClick(false)}
          visible={this.state.drawerOn}
        >
          <Menu theme="dark" className="admin-sidebar-menu" mode="inline" onClick={(e) => this.handleMenuOnClick(e.key)}
            defaultSelectedKeys={[this.state.activeMenu]}
            defaultOpenKeys={['restaurant', 'drivers']}>

            <Menu.Item key="dashboard">
              <Link to="/dashboard">
                <Icon type="appstore-o" />
                <span className="nav-text">Dashboard</span>
              </Link>
            </Menu.Item>

            {this.state.userType === 'Administrator' ?
            <SubMenu
              key="restaurant"
              title={
                <span>
                  <Icon type="rest" />
                  <span>Restaurants</span>
                </span>
              }
            >
              <Menu.Item key="restaurants">
                <Link to="/restaurants">
                  <span className="nav-text">Restaurants</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="restaurant-specialities">
                <Link to="/restaurant-specialities">
                  <span className="nav-text">Restaurant Specialities</span>
                </Link>
              </Menu.Item>
            </SubMenu>
            : <></>}

            {this.state.userType === 'Administrator' ?
            <Menu.Item key="drivers">
              <Link to="/drivers">
                <Icon type="car" />
                <span className="nav-text">Drivers</span>
              </Link>
            </Menu.Item>
             : <></>}

            <Menu.Item key="menus">
              <Link to="/menus">
                <Icon type="container" />
                <span className="nav-text">Menus</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="orders">
              <Link to="/orders">
                <Icon type="ordered-list" />
                <span className="nav-text">Orders</span>
              </Link>
            </Menu.Item>

            {this.state.userType === 'Administrator' ?
              <Menu.Item key="users">
                <Link to="/users">
                  <Icon type="team" />
                  <span className="nav-text">Users</span>
                </Link>
              </Menu.Item>
            : <></>}

            <Menu.Item key="coupons">
              <Link to="/coupons">
                <Icon type="percentage" />
                <span className="nav-text">Coupons</span>
              </Link>
            </Menu.Item>

          </Menu>
        </Drawer>
        <Layout className="active-sidebar">
          <Header className="admin-header">
            <div>
              <Button className="desktop-hide" type="primary" onClick={() => this.handleDrawerOnClick(true)}
                icon="menu-fold" />
            </div>
            <div>
              <Menu
                theme="light"
                mode="horizontal"
                style={{ lineHeight: '64px' }}
              >
                <Menu.Item key="2">
                  <Icon type="settings" />
                </Menu.Item>
                <Menu.Item key="3">
                  <Icon type="notification" />
                </Menu.Item>

                <SubMenu
                  title={<span className="submenu-title-wrapper">
                    <Icon type="user" />
                  </span>
                  }
                >
                  <Menu.Item key="setting:1">Settings</Menu.Item>
                  <Menu.Item key="" onClick={() => this.handleLogout()}>Logout</Menu.Item>
                </SubMenu>
              </Menu>
            </div>
          </Header>
          <Content style={{ padding: '0 15px', marginTop: 64 }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Admin</Breadcrumb.Item>
              <Breadcrumb.Item>{this.toTitleCase(this.state.activeUrl)}</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ padding: 24, minHeight: 380 }}>
              {this.props.children}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>{config.copyright}</Footer>
        </Layout>

      </Layout>
    );
  }

};

export default withRouter(AdminLayout);
