import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firebase-firestore'
import 'firebase/database'
import 'firebase/storage'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

if (process.env.REACT_APP_FIREBASE_MEASUREMENT_ID) {
	config.measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

class Firebase {
	// initiate the constructor
	constructor() {
		app.initializeApp(config);
		const app2 = app.initializeApp(config, "app2");
		this.auth = app.auth();
		this.authUser = app2.auth(); // create another database
		this.db = app.database(); // create the firebase database
		this.storage = app.storage().ref(); // create the storage reference
		this.serverTime = app.firestore.FieldValue.serverTimestamp(); // firebase server time
	}

	// login with firebase
	login(email, password) {
		return this.auth.signInWithEmailAndPassword(email, password)
	}

	// logout user
	logout() {
		return this.auth.signOut()
	}

	// register a new account
	async register(name, email, password) {
		await this.auth.createUserWithEmailAndPassword(email, password)
		return this.auth.currentUser.updateProfile({
			displayName: name
		})
	}

  sendPasswordResetEmail(email) {
    return this.auth.sendPasswordResetEmail(email)
  }

	// check if firebase intialized
	isInitialized() {
		return new Promise(resolve => {
			this.auth.onAuthStateChanged(resolve)
		})
	}

	// get the current user
	getCurrentUser() {
		return this.auth.currentUser && this.auth.currentUser
	}

	// get the current username
	getCurrentUsername() {
		return this.auth.currentUser && this.auth.currentUser.displayName
	}

	// get single user by the id
	getUserBy = async (id, callback) => {
		await this.db.ref("users").orderByChild("authID").equalTo(id).on('value', (snapshot) => {
			let data =  {};
			snapshot.forEach((childSnapshot) => {
				data = childSnapshot.val();
			});
			callback(data);
		});
	}

	// get all delivery peoples
	getDeliveryPeoples = async (callback) => {
		await this.db.ref("users/").on('value', function (snapshot) {
			let ddata = [];
			snapshot.forEach((childSnapshot) => {
				var childKey = childSnapshot.key;
				var childData = childSnapshot.val();
				if (childData.usertype === "Driver") {
					childData.key = childKey;
					childData.name = childData.firstname + ' ' + childData.lastname;
					ddata.push(childData);
				}
			});
			callback(ddata.reverse());
		});
	}

	// get all delivery peoples who are not approved
	getunApprovedDeliveryPeoples = async (callback) => {
		await this.db.ref("users/").on('value', function (snapshot) {
			let ddata = [];
			snapshot.forEach((childSnapshot) => {
				var childKey = childSnapshot.key;
				var childData = childSnapshot.val();
				if (childData.usertype === "Driver" && !childData.approved) {
					childData.key = childKey;
					childData.name = childData.firstname + ' ' + childData.lastname;
					ddata.push(childData);
				}
			});
			callback(ddata.reverse());
		});
	}

	// get all delivery peoples who are not blocked
	getBlockedDeliveryPeoples = async (callback) => {
		await this.db.ref("users/").on('value', function (snapshot) {
			let ddata = [];
			snapshot.forEach((childSnapshot) => {
				var childKey = childSnapshot.key;
				var childData = childSnapshot.val();
				if (childData.usertype === "Driver" && childData.blocked === true) {
					childData.key = childKey;
					childData.name = childData.firstname + ' ' + childData.lastname;
					ddata.push(childData);
				}
			});
			callback(ddata.reverse());
		});
	}

	// get all restaurant menu items
	getAllMenuItmes = (callback) => {
		const theDB = this.db;
		theDB.ref("restaurant_menu/").on('value', function (snapshot) {
			let ddata = [];
			snapshot.forEach((childSnapshot) => {
				var childKey = childSnapshot.key;
				let restaurantkey = childKey;

				childSnapshot.forEach((menuItem) => {
					let themenuitem = menuItem.val();
					themenuitem.key = menuItem.key;
					themenuitem.restaurant = !menuItem.restaurant && restaurantkey;
					ddata.push(themenuitem);
				});
			});
			callback(ddata.reverse());
		});
	}


	// create menu item
	createMenuItem = async (restaurant, menu, callback) => {
		await this.db.ref().child('restaurant_menu/'+restaurant).push(menu)
			.then(() => {
				callback({
					success: true
				});
			}).catch((error) => {
				callback({
					success: false,
					msg: error.message
				});
			});
	}

	// update menu item
	updateMenuItem = async (restaurant, id, menu, callback) => {
		await this.db.ref().child('restaurant_menu/'+restaurant+'/'+id).update(menu)
			.then(() => {
				callback({
					success: true
				});
			}).catch((error) => {
				callback({
					success: false,
					msg: error.message
				});
			});
	}

	// get all restaurants
	getAllRestaurants = (callback) => {
		this.db.ref("restaurant/").on('value', function (snapshot) {
			let ddata = [];
			snapshot.forEach((childSnapshot) => {
				var childKey = childSnapshot.key;
				var childData = childSnapshot.val();
				childData.key = childKey;
				ddata.push(childData);
			});
			callback(ddata.reverse());
		});
	}

	// create restaurant
	createrestaurant = async (restaurant, callback) => {
		await this.db.ref().child('restaurant').push(restaurant)
			.then(() => {
				callback({
					success: true
				});
			}).catch((error) => {
				callback({
					success: false,
					msg: error.message
				});
			});
	}

	// update menu category
	updateRestaurant = async (id, restaurant, callback) => {
		await this.db.ref().child('restaurant/'+id).update(restaurant)
			.then(() => {
				callback({
					success: true
				});
			}).catch((error) => {
				callback({
					success: false,
					msg: error.message
				});
			});
	}

	// get all users who are customer
	getCustomers = async (callback) => {
		await this.db.ref("users/").orderByChild("usertype").equalTo("Customer").on('value', function (snapshot) {
			let ddata = [];
			snapshot.forEach((childSnapshot) => {
				let childData = childSnapshot.val();
				childData.key = childSnapshot.key;
				childData.name = childData.firstname + ' ' + childData.lastname;
				ddata.push(childData);
			});
			callback(ddata.reverse());
		});
	}

	// get drivers
	getDrivers = async (callback) => {
		await this.db.ref("users/").orderByChild("usertype").equalTo("Driver").on('value', function (snapshot) {
			let ddata = [];
			snapshot.forEach((childSnapshot) => {
				let childData = childSnapshot.val();
				childData.key = childSnapshot.key;
				childData.name = childData.firstname + ' ' + childData.lastname;
				ddata.push(childData);
			});
			callback(ddata.reverse());
		});
	}

	// get restaurant owner
	getUserRestaurants = async (callback) => {
		await this.db.ref("users/").orderByChild("usertype").equalTo("Restaurant").on('value', function (snapshot) {
			let ddata = [];
			snapshot.forEach((childSnapshot) => {
				let childData = childSnapshot.val();
				childData.key = childSnapshot.key;
				childData.name = childData.firstname + ' ' + childData.lastname;
				ddata.push(childData);
			});
			callback(ddata.reverse());
		});
	}

	// get administrator user
	getAdministrators = async (callback) => {
		await this.db.ref("users/").orderByChild("usertype").equalTo("Administrator").on('value', function (snapshot) {
			let ddata = [];
			snapshot.forEach((childSnapshot) => {
				let childData = childSnapshot.val();
				childData.key = childSnapshot.key;
				childData.name = childData.firstname + ' ' + childData.lastname;
				ddata.push(childData);
			});
			callback(ddata.reverse());
		});
	}

	// get restaurant by ID
	getRestaurantByID = async (restoKey, callback) => {
		await this.db.ref("restaurant/" + restoKey).on('value', function (snapshot) {
			var snap = snapshot.val();
			callback(snap);
		});
	}

	// get all orders
	getOrders = async (status, callback) => {
		await this.db.ref("orders/").orderByChild("createdAt").on('value', function (snapshot) {
			let ddata = [];
			snapshot.forEach((childSnapshot) => {
				let tmpChildData = childSnapshot.val();
				let childData = {};
				Object.keys(tmpChildData).forEach(function (key, values) {
					if (tmpChildData[key].status === status) {
						childData = tmpChildData[key];
						childData.dateCreated = new Date(tmpChildData[key].createdAt).toString();
						childData.createdAt = new Date(tmpChildData[key].createdAt);
						childData.key = key;
						childData.nodeid = childSnapshot.key;
						let asproducts = tmpChildData[key].products;
						let productList = "";

						childData.productList = Object.keys(asproducts).forEach(function (key, values) {
							productList += asproducts[key].name + ", ";
						});
						let products = [];
						let totalamount = 0;
						for (let pi in tmpChildData[key].products){
							totalamount += parseInt(tmpChildData[key].products[pi].quantity)*parseFloat(tmpChildData[key].products[pi].price);
							products.push(tmpChildData[key].products[pi]);
						}

						childData.products = products;
						childData.total = totalamount + parseFloat(childData.tax) + parseFloat(childData.donate) + parseFloat(childData.servicefee) + parseFloat(childData.tipAmount) + parseFloat(childData.deliveryfee) - parseFloat(childData.discount);

						childData.productList = productList;
						childData.id = childSnapshot.key;
						ddata.push(childData);
					}
				});
			});

			let orderdata = ddata.sort((a, b) => a.createdAt - b.createdAt)

			callback(orderdata.reverse());
		});
	}

	// create menu category
	createMenuCategory = async (category, callback) => {
		await this.db.ref().child('menu_category').push(category)
			.then(() => {
				callback({
					success: true
				});
			}).catch((error) => {
				callback({
					success: false,
					msg: error.message
				});
			});
	}

	// update menu category
	updateMenuCategory = async (id, category, callback) => {
		await this.db.ref().child('menu_category/'+id).update(category)
			.then(() => {
				callback({
					success: true
				});
			}).catch((error) => {
				callback({
					success: false,
					msg: error.message
				});
			});
	}

	// delete menu category
	deleteMenuCategory = async (id, category, callback) => {
		let catimage = category.image;

		await this.db.ref().child('menu_category/'+id).set(null)
			.then(() => {
				if (catimage) {
					// Create a reference to the file to delete
					var catImageRef = this.storage.child('menu_category/'+catimage);

					// Delete the file
					catImageRef.delete().then(function() {
						callback({
							success: true
						});
					}).catch(function(error) {
						console.log('error', error);
						callback({
							success: true
						});
					});
				}
			}).catch((error) => {
				callback({
					success: false,
					msg: error.message
				});
			});
	}

	// get categories
	getCategories = async (callback) => {
		await this.db.ref("menu_category/").orderByChild("name").on('value', function (snapshot) {
			let ddata = [];
			snapshot.forEach((childSnapshot) => {
				let childData = childSnapshot.val();
				childData.key = childSnapshot.key;
				ddata.push(childData);
			});
			callback(ddata.reverse());
		});
	}

	// create a new cuisine
	createCuisine = async (cuisine, callback) => {
		await this.db.ref().child('cuisines').push(cuisine)
		.then(() => {
			callback({
				success: true
			});
		}).catch((error) => {
			callback({
				success: false,
				msg: error.message
			});
		});
	}

	// get all cuisines
	getCuisines = async (callback) => {
		await this.db.ref("cuisines/").on('value', (snapshot) => {
			let ddata = [];
			snapshot.forEach((childSnapshot) => {
				let childData = childSnapshot.val();
				childData.id = childSnapshot.key;
				ddata.push(childData);
			});
			callback(ddata.reverse());
		});
	}

	// delete cuisine by ID
	deleteCuisine = async (id,callback) => {
		await this.db.ref("cuisines/"+id).remove()
		.then(() => {
			callback({
				success: true
			});
		})
		.catch((error) => {
			callback({
				success: false,
				msg: error.message
			});
		});
	}

	// update a cuisine
	updateCuisine = async (id, cuisine, callback)=>{
		await this.db.ref('cuisines/' + id).update(cuisine).then(()=> {
           callback({
			   success: true,
		   });
        }).catch((error) => {
			callback({
				success: false,
				msg: error.message
			});
        });
	}

	// create restaurant speciality
	createSpeciality = async (speciality, callback) => {
		await this.db.ref().child('restaurant_speciality').push(speciality)
			.then(() => {
				callback({
					success: true
				});
			}).catch((error) => {
				callback({
					success: false,
					msg: error.message
				});
			});
	}

	// update restaurant speciality
	updateSpeciality = async (id, speciality, callback) => {
		await this.db.ref().child('restaurant_speciality/'+id).update(speciality)
			.then(() => {
				callback({
					success: true
				});
			}).catch((error) => {
				callback({
					success: false,
					msg: error.message
				});
			});
	}

	// get specialities
	getSpecialities = async (callback) => {
		await this.db.ref("restaurant_speciality/").on('value', (snapshot) => {
			let ddata = [];
			snapshot.forEach((childSnapshot) => {
				let childData = childSnapshot.val();
				childData.id = childSnapshot.key;
				childData.key = childSnapshot.key;
				ddata.push(childData);
			});
			callback(ddata.reverse());
		});
	}

	// delete specialities
	deleteSpecialities = async (id,callback) => {
		await this.db.ref("restaurant_speciality/"+id).remove()
			.then(() => {
				callback({
					success: true
				});
			})
			.catch((error) => {
				callback({
					success: false,
					msg: error.message
				});
			});
	}

	// get coupons
	getCoupons = async (callback) => {
		await this.db.ref("coupon_code/").on('value', (snapshot) => {
			let ddata = [];
			snapshot.forEach((childSnapshot) => {
				let childData = childSnapshot.val();
				childData.id = childSnapshot.key;
				childData.key = childSnapshot.key;
				ddata.push(childData);
			});
			callback(ddata.reverse());
		});
	}

	// create coupon
	createCoupon = async (coupon, callback) => {
		await this.db.ref().child('coupon_code').push(coupon)
			.then(() => {
				callback({
					success: true
				});
			}).catch((error) => {
				callback({
					success: false,
					msg: error.message
				});
			});
	}

	// update coupon
	updateCoupon = async (id, coupon, callback) => {
		await this.db.ref().child('coupon_code/'+id).update(coupon)
			.then(() => {
				callback({
					success: true
				});
			}).catch((error) => {
				callback({
					success: false,
					msg: error.message
				});
			});
	}

	// delete coupon
	deleteCoupon = async (id,callback) => {
		await this.db.ref("coupon_code/"+id).remove()
			.then(() => {
				callback({
					success: true
				});
			})
			.catch((error) => {
				callback({
					success: false,
					msg: error.message
				});
			});
	}

}

export default new Firebase()
