import React, {useEffect, useState} from 'react';
import { Link, withRouter } from 'react-router-dom'
import firebase from '../../firebase'
import AdminLayout from "../../components/AdminLayout";
import {Button, Input, Modal, Table, Tag, Icon, Drawer, Switch, Select} from "antd";
import Highlighter from 'react-highlight-words';
import EditDeliveryPeople from "./edit";
const { Option } = Select;
const {confirm} = Modal;
const ButtonGroup = Button.Group;

const DeliveryPeople = (props) => {
    let searchInput;
    const [loadingDeliveryPeople, setloadingDeliveryPeople] = useState(false);
    const [deliveryPeoples, setDeliveryPeoples] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [single, setSingle] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [restaurants, setRestaurants] = useState([]);

    // get all essentials
    useEffect(() => {
        getAllDeliveryPeopples();
        let tmprestaurants = [];
        firebase.getAllRestaurants((data) => {
            data.forEach(restaurant => {
                tmprestaurants.push(<Option key={restaurant.key}>{restaurant.name}</Option>);
                setRestaurants(tmprestaurants);
            });
        })
    }, []);

    const getAllDeliveryPeopples = () => {
        setloadingDeliveryPeople(true);
        firebase.getDeliveryPeoples((data) => {
            setDeliveryPeoples(data);
            setloadingDeliveryPeople(false);
        })
    }

    const deleteDriver = (id) => {
        confirm({
            title: 'Do you want to delete these Driver?',
            content: 'After deleted you cannot retrieve one you deleted.',
            onOk() {
                let driverRef = firebase.db.ref('users/' + id);
                driverRef.remove().then( () => {
                    Modal.success({
                        content: 'Driver successfully deleted!'
                    });
                });
            },
            onCancel() {},
        });
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText("");
    };

    const openDrawer = () => {
        setDrawerOpen(true);
    }

    const closeDrawer = () => {
        setSingle(false);
        setDrawerOpen(false);
    }

    const blockDriver = (id, blockedStatus) => {
        let driverRef = firebase.db.ref('users/' + id + '/blocked').set(blockedStatus);
        driverRef.then(() => {
            let msgPart = blockedStatus ? "blocked" : "unblocked";
            Modal.success({
                content: (
                    <h4>Driver has been {msgPart}.</h4>
                )
            })
        })
    }

    const changeStatus = (id, status) => {
      var statusDesc = "";
      // check the status type
      if (status === 0) {
          statusDesc = "Offline";
      } else if (status === 1) {
          statusDesc = "Online";
      }
      // change restaurant status to online/offline by ID
      let restoRef = firebase.db.ref('users/' + id + '/status').set(status);
      restoRef.then(() => {
          Modal.success({
              content: (
                  "Driver has been changed to " + statusDesc
              )
          });
      });
    }

    const changeApproval = (id, status) => {
        var statusDesc = "";
        // check the status type
        if (status === 0) {
            statusDesc = "Not Approved";
        } else if (status === 1) {
            statusDesc = "Approved";
        }
        // change restaurant status to online/offline by ID
        let restoRef = firebase.db.ref('users/' + id + '/approved').set(status);
        restoRef.then(() => {
            Modal.success({
                content: (
                    "Driver has been changed to " + statusDesc
                )
            });
        });
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Vehicle Type',
            dataIndex: 'vehicleType',
            key: 'vehicleType',
        },
        {
            title: 'Contact No.',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Rating',
            dataIndex: 'rating',
            key: 'rating',
        },
        {
            title: 'Approved',
            dataIndex: 'approved',
            key: 'approved',
            render: (text, record) => {
                return <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked={record.approved}
                    style={{
                        marginRight: 10
                    }}
                    onChange={(e) => {
                        changeApproval(record.key, e ? 1 : 0);
                    }}
                />
            }
        },
        {
            title: 'Online/Offline',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (record.status && record.status === 1 ?
              <Button type="primary" size="small" onClick={() => changeStatus(record.key, 0)}>Online</Button> :
              <Button type="danger" size="small" onClick={() => changeStatus(record.key, 1)}>Offline</Button>)
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <div>
                        <Switch
                            size="small"
                            defaultChecked={record.blocked}
                            style={{
                                marginRight: 10
                            }}
                            onChange={(e) => {
                                blockDriver(record.key, e);
                            }}
                        />
                        <ButtonGroup>
                            <Button
                                onClick={() => {
                                    console.log(record);

                                    setSingle(record);
                                    openDrawer();
                                }}
                                size="small"
                                type="primary"
                                icon="edit" />
                            <Button
                                size="small"
                                type="danger"
                                icon="close"
                                onClick={() => deleteDriver(record.key)}
                            />
                        </ButtonGroup>
                    </div>
                )
            }
        },
    ];

    return (
        <AdminLayout {...props}>
            <div className="form-inner-container">
              <div class="page-actions">
                  <Button type="primary" style={{ marginBottom: 10 }}>
                      <Link to="/add-driver">
                          <Icon type="driver-add" />
                          <span className="nav-text"> Add Driver</span>
                      </Link>
                  </Button>
                </div>
                <div className="card">
                    <div className="card-header">
                        <div>
                            <h5>Drivers</h5>
                        </div>
                        <div>

                        </div>
                    </div>
                    <div className="card-block">
                        <Table
                            size="small"
                            loading={loadingDeliveryPeople}
                            columns={columns}
                            dataSource={deliveryPeoples}
                        />
                    </div>
                </div>
            </div>

            <Drawer
                title="Update Driver"
                placement="right"
                width="100%"
                onClose={closeDrawer}
                visible={drawerOpen}
            >
                <EditDeliveryPeople single={single} key={single.key} onSuccess={closeDrawer} restaurants={restaurants} />
            </Drawer>

        </AdminLayout>
    );

};

export default withRouter(DeliveryPeople);
