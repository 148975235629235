import React, { useState } from 'react';
import firebase from '../../firebase';
import MapBoxComponent from "../../components/MapBoxComponent";
import {
    Icon, Button, Input, Modal, Select,
    Form, Col, Row, message, Upload, Drawer, Divider
} from 'antd';
const { Option } = Select;
const EditUserWrapper = (props) => {
    const selectedUser = props.selectedUser;

    const { getFieldDecorator } = props.form;
    const [updatingUser, setUpdatingUser] = useState(false);
    const [locationState, setLocationState] = useState("");
    const [newAddress, setAddress] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [loadingUploader, setLoadingUploader] = useState(false);
    const [ppMimeType, setppMimeType] = useState("image/jpeg");
    const [avatar, setImageUrl] = useState(false);

    const callbackFunction = (childData) => {
        setAddress(childData.result.place_name);
        setLatitude(childData.result.geometry.coordinates[1]);
        setLongitude(childData.result.geometry.coordinates[0]);
        setLocationState(childData.result.text);
    };

    // call before upload profile image
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    // get base64 image on select image
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', (evt) => {
            callback(reader.result, img.type)
        });
        reader.readAsDataURL(img);
    }

    // create base64 on change uploader
    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoadingUploader(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl, type) => {
                setImageUrl(imageUrl);
                let mimeType = type === "image/jpeg" ? ".jpg" : ".png";
                setppMimeType(mimeType);
                setLoadingUploader(true);
            });
        }
    };

    // create upload btn
    const uploadButton = (
        <div>
            <Icon type={loadingUploader ? 'loading' : 'plus'} />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 16 },
        },
    };

    const formItemcs = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    }

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setUpdatingUser(true);
                if (avatar !== "" && avatar) {
                    const userToBeUpdated = firebase.db.ref('users/' + selectedUser.key);
                    userToBeUpdated.once('value').then((snapshot) => {
                        var user = snapshot.val();
                        if (user.avatar !=="") {
                            let imagepath = user.avatar.split("/");
                            let image = imagepath[imagepath.length - 1].split("?");
                            image = image[0].split("%2F");
                            var imgRef = firebase.storage.child('profile_image/' + image[1]);
                            imgRef.delete();
                        }
                    });

                    var uploadTaskProfile = firebase.storage.child('profile_image/' + selectedUser.key + ppMimeType).putString(avatar, 'data_url');
                    uploadTaskProfile.on('state_changed', (snapshot) => {
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        if (progress === 100) {
                            console.log('upload', 'uploaddone');
                        }
                    }, (error) => {
                        console.log('Filed uploading banner image');
                    }, () => {
                        uploadTaskProfile.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            saveDataToFirebase(values, downloadURL);
                        });
                    });
                } else {
                    saveDataToFirebase(values);
                }
            }
        });
    };
    const saveDataToFirebase = async (updated_user, avatar = "") => {
        let updatedUser = {
            firstname: updated_user.firstname,
            lastname: updated_user.lastname,
            contact_number: updated_user.contact_number,
            usertype: updated_user.usertype,
        };

        if (newAddress !== "") {
            const address = {
                state: locationState,
                address: updated_user.address,
                lat: latitude,
                lng: longitude,
                zipcode: "",
            };
            updatedUser = { ...updatedUser, ...address };
        }

        if (avatar !== "" || avatar) {
            const avatarObj = {
                avatar: avatar
            }
            updatedUser = { ...updatedUser, ...avatarObj };
        }

        firebase.db.ref('users/' + selectedUser.key).update(updatedUser).then(()=> {
            Modal.success({
                title: 'Updating user',
                content: 'User has been successfully updated!',
            });
            setUpdatingUser(false);
            props.openEditForm(false)
            props.form.resetFields();
            setImageUrl(false);
            setLoadingUploader(false);
        }).catch((error) => {
            Modal.error({
                title: 'Updating user failed',
                content: error.message,
            });
        });

    };

    return (
        <div>
            <Drawer
                title="Update User Details"
                width={720}
                onClose={() => props.openEditForm(false)}
                visible={props.isVisible}
                style={{
                    overflow: 'auto',
                    height: 'calc(100%)',
                    paddingBottom: '108px',
                }}
            >
                <Form labelAlign="left" {...formItemLayout} onSubmit={handleSubmit}>
                    <Row gutter={[48, 24]} justify="space-around" align="middle" >
                        <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}>
                            Profile Details
                        </Divider>
                        <Col xs={{ span: 10 }} lg={{ span: 10 }}>
                            <Form.Item label="Avatar"  {...formItemcs}>
                                <Upload
                                    method="get"
                                    name="avatar"
                                    listType="picture-card"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                >
                                    {selectedUser.avatar !== "" ? avatar ? <img src={avatar} alt="Avatar" style={{ width: '100%' }} /> : <img src={selectedUser.avatar} alt="Avatar" style={{ width: '100%' }} /> : avatar ? <img src={avatar} alt="Avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 14 }} lg={{ span: 14 }}>
                            <Row gutter={[0, 10]}>
                                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                    <Form.Item label="First Name" {...formItemcs}>
                                        {getFieldDecorator('firstname', {
                                            initialValue: selectedUser.firstname,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input first name!',
                                                },
                                            ],
                                        })(<Input placeholder="First Name" />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                    <Form.Item label="Last Name" {...formItemcs}>
                                        {getFieldDecorator('lastname', {
                                            initialValue: selectedUser.lastname,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input last name!',
                                                },
                                            ],
                                        })(<Input placeholder="Last Name" />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                    <Form.Item label="Contact No." {...formItemcs}>
                                        {getFieldDecorator('contact_number', {
                                            initialValue: selectedUser.contact_number,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input contact no!',
                                                },
                                            ],
                                        })(<Input placeholder="Contact No" />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                    <Form.Item label="Address:" {...formItemcs}>
                                        {getFieldDecorator('address', {
                                            initialValue: newAddress === "" ? selectedUser.address : newAddress,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Select address on map',
                                                },
                                            ],
                                        })(<Input placeholder="Select address on map" />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                    <div style={{ height: '280px', width: '100%' }}>
                                        <MapBoxComponent mapboxCallback={callbackFunction} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row gutter={10}  style={{marginBottom:60}}>
                        <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}>
                            Account Details
                        </Divider>

                        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                            <Form.Item label="User Type" {...formItemcs}>
                                {getFieldDecorator('usertype', {
                                    initialValue: selectedUser.usertype,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select user type!',
                                        },
                                    ],
                                })(<Select>
                                    <Option value="Customer">Customer</Option>
                                    <Option value="Driver">Driver</Option>
                                    <Option value="Restaurant">Restaurant</Option>
                                    <Option value="Administrator">Administrator</Option>
                                </Select>)}

                            </Form.Item>
                        </Col>
                    </Row>

                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 16px',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => props.openEditForm(false)} size="large" style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        <Button disabled={updatingUser} loading={updatingUser} size="large" type="primary" htmlType="submit">
                            {updatingUser ? "Updating please wait . . " : "Save"}
                        </Button>
                    </div>
                </Form>
            </Drawer>
        </div>
    );
}

const EditUser = Form.create({ name: 'update-user' })(EditUserWrapper);

export default EditUser;
