import React from "react";

import MapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import DeckGL, { GeoJsonLayer } from "deck.gl";

import Geocoder from "react-map-gl-geocoder";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

class MapBoxComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapRef: React.createRef(),
      viewport: {
        latitude: 36.778259,
        longitude: -119.417931,
        zoom: 8
      },
      searchResultLayer: null
    };
  }

  handleViewportChange = viewport => {
    this.setState({
      viewport: { ...this.state.viewport, ...viewport }
    });
  };

  handleGeocoderViewportChange = viewport => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };

    return this.handleViewportChange({
      ...viewport,
      ...geocoderDefaultOverrides
    });
  };

  handleOnResult = event => {
    this.props.mapboxCallback(event);

    this.setState({
      searchResultLayer: new GeoJsonLayer({
        id: "search-result",
        data: event.result.geometry,
        getFillColor: [255, 0, 0, 128],
        getRadius: 1000,
        pointRadiusMinPixels: 10,
        pointRadiusMaxPixels: 10
      })
    });
  };

  render() {
    const { viewport, searchResultLayer } = this.state;

    return (
      <div style={{ height: "100%" }}>
        <MapGL
          ref={this.state.mapRef}
          {...viewport}
          width="100%"
          height={250}
          onViewportChange={this.handleViewportChange}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        >
          <Geocoder
            mapRef={this.state.mapRef}
            onResult={this.handleOnResult}
            onViewportChange={this.handleGeocoderViewportChange}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            position="top-left"
          />
          <DeckGL {...viewport} layers={[searchResultLayer]} />
        </MapGL>
      </div>
    );
  }
}

export default MapBoxComponent
