import React, {useContext, useState} from 'react';
import {Form, Input, Icon, Button, message, Upload, Modal} from "antd";
import firebase from "../../firebase";
import config from "../../../config";

const AddMenuCategoryForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(false);
    const [single, setSingle] = useState(props.single && props.single);

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                setImageUrl(imageUrl);
                if (single){
                    setSingle({
                        ...single,
                        image: ""
                    })
                }
                setLoading(false);
            });
        }
    };

    const randomString = (length) => {
        let chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (single) {
                    updateCategory(single.key, values);
                } else {
                    addNewCategory(values);
                }
            }
        });
    };

    const addNewCategory = (values) => {
        setLoading(true);
        if (values.image.fileList[0]) {
            let imageext = values.image.fileList[0].type === "image/jpeg" ? ".jpg" : ".png"
            let imagename = randomString(20) + imageext;
            let uploadTask = firebase.storage.child('menu_category/' + imagename).put(values.image.fileList[0].originFileObj);
            uploadTask.on('state_changed', function (snapshot) {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            }, function (error) {
                Modal.error({
                    title: 'Sorry',
                    content: error
                })
                setLoading(false);
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    firebase.createMenuCategory({
                        name: values.name,
                        image: imagename
                    }, (addedresp) => {
                        if (addedresp.success) {
                            Modal.success({
                                title: "Success!",
                                content: "Category successfully added."
                            });
                            setLoading(false);
                            props.onSuccess();
                        } else {
                            Modal.error({
                                title: "Sorry!",
                                content: addedresp.msg
                            });
                            setLoading(false);
                        }
                    })
                });
            });
        } else {
            firebase.createMenuCategory({
                name: values.name,
                image: ""
            }, (addedresp) => {
                if (addedresp.success) {
                    Modal.success({
                        title: "Success!",
                        content: "Category successfully added."
                    });
                    setLoading(false);
                    props.onSuccess();
                } else {
                    Modal.error({
                        title: "Sorry!",
                        content: addedresp.msg
                    });
                    setLoading(false);
                }
            })
        }
    }

    const updateCategory = (id, values) => {
        setLoading(true);
        if (single && !single.image && values.image.fileList[0]) {
            let imageext = values.image.fileList[0].type === "image/jpeg" ? ".jpg" : ".png"
            let imagename = randomString(20) + imageext;
            let uploadTask = firebase.storage.child('menu_category/' + imagename).put(values.image.fileList[0].originFileObj);
            uploadTask.on('state_changed', function (snapshot) {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            }, function (error) {
                Modal.error({
                    title: 'Sorry',
                    content: error
                })
                setLoading(false);
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    firebase.updateMenuCategory(id,{
                        name: values.name,
                        image: imagename
                    }, (addedresp) => {
                        if (addedresp.success) {
                            Modal.success({
                                title: "Success!",
                                content: "Category successfully updated."
                            });
                            setLoading(false);
                            props.onSuccess();
                        } else {
                            Modal.error({
                                title: "Sorry!",
                                content: addedresp.msg
                            });
                            setLoading(false);
                        }
                    })
                });
            });
        } else {
            firebase.updateMenuCategory(id,{
                name: values.name,
                image: single.image ? single.image : ""
            }, (addedresp) => {
                if (addedresp.success) {
                    Modal.success({
                        title: "Success!",
                        content: "Category successfully updated."
                    });
                    setLoading(false);
                    props.onSuccess();
                } else {
                    Modal.error({
                        title: "Sorry!",
                        content: addedresp.msg
                    });
                    setLoading(false);
                }
            })
        }
    }

    const dummyRequest = ({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const {getFieldDecorator} = props.form;

    const uploadButton = (
        <div>
            <Icon type={loading ? 'loading' : 'plus'}/>
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item>
                {getFieldDecorator('name', {
                    initialValue: single ? single.name : "",
                    rules: [{required: true, message: 'Please input category name!'}],
                })(
                    <Input
                        placeholder="Category Name"
                    />,
                )}
            </Form.Item>

            <Form.Item label="Select category Image">
                {getFieldDecorator('image', {
                    initialValue: [],
                    valuePropName: 'files',
                })(
                    <Upload
                        name="category_image"
                        listType="picture-card"
                        className="image-uploader"
                        showUploadList={false}
                        customRequest={dummyRequest}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                    >
                        {
                            single.image ?
                                <img
                                    src={`https://firebasestorage.googleapis.com/v0/b/${config.storageBucket}/o/menu_category%2F${single.image}?alt=media`}
                                    alt="menu_category_image" style={{width: '100%'}}/> :
                                imageUrl ?
                                    <img src={imageUrl} alt="menu_category_image" style={{width: '100%'}}/>
                                    : uploadButton
                        }
                    </Upload>,
                )}
            </Form.Item>

            <Form.Item>
                <Button type="primary" disabled={loading} loading={loading} htmlType="submit" block>
                    {single ? "Update" : "Save"} Menu Category
                </Button>
                <Button type="danger" block
                        onClick={() => {
                            props.onCancel();
                        }}
                >
                    Cancel
                </Button>
            </Form.Item>
        </Form>
    );
};

const AddMenuCategory = Form.create({name: 'addmenucategory'})(AddMenuCategoryForm);

export default AddMenuCategory;
