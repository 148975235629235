import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom'
import firebase from '../../firebase'
import AdminLayout from "../../components/AdminLayout";
import MapBoxComponent from "../../components/MapBoxComponent";
import axios from "axios";

import {
    Modal, message,
    Form, Button, Input, Upload,
    Row, Col, Icon, Select, Card, Divider
} from 'antd';
const { Option } = Select;
const AddUserWrapper = (props) => {
    const { getFieldDecorator } = props.form;
    const [savingUser, setSavingUser] = useState(false);
    const [countryCode, setCountryCode] = useState("");
    const [locationState, setLocationState] = useState("");
    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");

    const [loadingUploader, setLoadingUploader] = useState(false);
    const [ppMimeType, setppMimeType] = useState("image/jpeg");
    const [avatar, setImageUrl] = useState(false);

    const history = useHistory();
    // get initials
    useEffect(() => {
        getGeoInfo();
    })

    const getGeoInfo = () => {
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            if (data.country_calling_code) {
                setCountryCode(data.country_calling_code);
            }
            else {
                setCountryCode("+1");
            }
        }).catch((error) => {
            setCountryCode("+1");
        });

    };

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setSavingUser(true);
                firebase.authUser.createUserWithEmailAndPassword(values.email, values.password).then((account) => {
                    let user = account.user;
                    if (avatar) {
                        var uploadTaskProfile = firebase.storage.child('profile_image/' + user.uid + ppMimeType).putString(avatar, 'data_url');
                        uploadTaskProfile.on('state_changed', function (snapshot) {
                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                            if (progress === 100) {
                                console.log('upload', 'uploaddone');
                            }

                        }, function (error) {
                            console.log('Filed uploading banner image');
                        }, function () {
                            uploadTaskProfile.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                                user.updateProfile({
                                    displayName: values.firstname + " " + values.lastname,
                                    photoURL: downloadURL,
                                }).then(() => {
                                    saveDataToFirebase(user, values, downloadURL);
                                }, (error) => {
                                    setSavingUser(false);
                                    Modal.error({
                                        title: 'Creating user failed',
                                        content: error.message,
                                    });
                                    console.log('Creating user failed', error);
                                });
                            });
                        });
                    } else {
                        user.updateProfile({
                            displayName: values.firstname + " " + values.lastname,
                        })
                        saveDataToFirebase(user, values);
                    }
                }, function (error) {
                    setSavingUser(false);
                    Modal.error({
                        title: 'Creating user failed',
                        content: error.message,
                    });
                });
            }
        });
    };

    const saveDataToFirebase = async (auth, new_user, avatar = "") => {
        console.log(new_user);
        const newUser = {
            authID: auth.uid,
            firstname: new_user.firstname,
            lastname: new_user.lastname,
            contact_number: new_user.contact_number,
            email: new_user.email,
            state: locationState,
            address: new_user.address,
            lat: latitude,
            lng: longitude,
            zip: "",
            usertype: new_user.usertype,
            avatar: avatar,
            datecreated: firebase.serverTime,
        };

        firebase.db.ref().child('users').push(newUser)
            .then(() => {
                Modal.confirm({
                    title: 'User',
                    content: 'User has been successfully added!',
                    icon: <Icon type="check-circle-o" style={{ color: "#52c41a" }} />,
                    okText: 'Ok',
                    cancelText: 'Add Another User',
                    onOk() {
                        history.push('/users');
                    },
                    onCancel() {
                        setSavingUser(false);
                        props.form.resetFields();
                        setImageUrl(false);
                        setLoadingUploader(false);
                    },
                });
            }).catch((error) => {
                Modal.error({
                    title: 'Creating user failed',
                    content: error.message,
                });
            });

    };

    const callbackFunction = (childData) => {
        setAddress(childData.result.place_name);
        setLatitude(childData.result.geometry.coordinates[1]);
        setLongitude(childData.result.geometry.coordinates[0]);
        setLocationState(childData.result.text);
    };

    // call before upload profile image
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    // get base64 image on select image
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', (evt) => {
            callback(reader.result, img.type)
        });
        reader.readAsDataURL(img);
    }

    // create base64 on change uploader
    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoadingUploader(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl, type) => {
                setImageUrl(imageUrl);
                let mimeType = type === "image/jpeg" ? ".jpg" : ".png";
                setppMimeType(mimeType);
                setLoadingUploader(true);
            });
        }
    };

    // create upload btn
    const uploadButton = (
        <div>
            <Icon type={loadingUploader ? 'loading' : 'plus'} />
            <div className="ant-upload-text">Upload</div>
        </div>
    );


    // basic form label layout
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 16 },
        },
    };

    const formItemcs = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    }

    return (
        <AdminLayout {...props}>
            <div>
                <Form labelAlign="left" {...formItemLayout} onSubmit={handleSubmit}>
                <Col xs={{ span: 24 }} lg={{ span: 20 }} offset={2}>
                    <Card
                        title="Add User Form"
                        actions={[
                            <Button disabled={savingUser} loading={savingUser} size="large" type="primary" htmlType="submit">
                                {savingUser ? "Saving please wait . . " : "Save"}
                            </Button>
                          ]}
                    >
                        <Row gutter={[48, 0]} justify="space-around" align="middle" >
                            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                                <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}>
                                    Profile Details
                                </Divider>
                                <Row>
                                    <Col xs={{ span: 24 }} lg={{ span: 24 }} offset={4}>
                                        <Form.Item label="Avatar"  {...formItemcs}>
                                            <Upload
                                                method="get"
                                                name="avatar"
                                                listType="picture-card"
                                                showUploadList={false}
                                                beforeUpload={beforeUpload}
                                                onChange={handleChange}
                                            >
                                                {avatar ? <img src={avatar} alt="Avatar" style={{ width: '100%' }} /> : uploadButton}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ marginBottom: 15 }}>
                                        <Form.Item label="First Name" {...formItemcs}>
                                            {getFieldDecorator('firstname', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input first name!',
                                                    },
                                                ],
                                            })(<Input placeholder="First Name" />)}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ marginBottom: 15 }}>
                                        <Form.Item label="Last Name" {...formItemcs}>
                                            {getFieldDecorator('lastname', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input last name!',
                                                    },
                                                ],
                                            })(<Input placeholder="Last Name" />)}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ marginBottom: 15 }}>
                                        <Form.Item label="Contact No." {...formItemcs}>
                                            {getFieldDecorator('contact_number', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input contact no!',
                                                    },
                                                ],
                                            })(<Input placeholder="Contact No" />)}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                        <Form.Item label="Address:" {...formItemcs}>
                                            {getFieldDecorator('address', {
                                                initialValue: address,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Select address on map',
                                                        readonly: true
                                                    },
                                                ],
                                            })(<Input placeholder="Select address on map" />)}
                                        </Form.Item>
                                    </Col>

                                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                        <div style={{ height: '280px', width: '100%' }}>
                                            <MapBoxComponent mapboxCallback={callbackFunction} />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 14 }}>
                                <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}>
                                    Account Details
                                </Divider>
                                <Row gutter={[5, 24]}>
                                    <Col xs={{ span: 24 }} lg={{ span: 24 }} >
                                        <Form.Item label="User Type" {...formItemcs}>
                                            {getFieldDecorator('usertype', {
                                                initialValue: "Customer",
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please select user type!',
                                                    },
                                                ],
                                            })(
                                            <Select>
                                                <Option value="Customer">Customer</Option>
                                                <Option value="Driver">Driver</Option>
                                                <Option value="Restaurant">Restaurant</Option>
                                                <Option value="Administrator">Administrator</Option>
                                            </Select>)}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                        <Form.Item label="Email Address" {...formItemcs}>
                                            {getFieldDecorator('email', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input email address!',
                                                    },
                                                ],
                                            })(<Input placeholder="Email Address" />)}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                        <Form.Item label="Password" {...formItemcs}>
                                            {getFieldDecorator('password', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input password!',
                                                    },
                                                ],
                                            })(<Input.Password placeholder="Password" />)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                </Form>
            </div>
        </AdminLayout>
    );
};

const AddUser = Form.create({ name: 'add-user' })(AddUserWrapper);

export default withRouter(AddUser);

