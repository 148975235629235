import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Icon, Modal, Table} from "antd";
import AdminLayout from "../../components/AdminLayout";
import AddMenuCategory from "./AddMenuCategory";
import firebase from "../../firebase";
import config from "../../../config";

const MenuCategories = (props) => {
    const [loadingMenus, setLoadingMenus] = useState(false);
    const [addMenuCategoryModal, setAddMenuCategoryModal] = useState(false);
    const [menusCategories, setMenusCategories] = useState([]);
    const [single, setSingle] = useState(false);

    useEffect(() => {
        setLoadingMenus(true);
        firebase.getCategories((resp) => {
            setMenusCategories(resp);
            setLoadingMenus(false);
        })
    }, [])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: '30%',
            render: text => {
                return (<img style={{height: 25}}
                             src={`https://firebasestorage.googleapis.com/v0/b/${config.storageBucket}/o/menu_category%2F${text}?alt=media`}/>)
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button size="small" type="primary" style={{marginRight: "5px"}} onClick={() => {
                        setSingle(record);
                        setAddMenuCategoryModal(true);
                    }} icon="edit"/>
                    <Button size="small" type="danger"
                        onClick={() => {
                            Modal.confirm({
                                title: 'Do you want to delete these category?',
                                content: 'Once you delete this category, you cannot retrieve later.',
                                onOk() {
                                    return new firebase.deleteMenuCategory(record.key, record, (delresp) => {
                                        Modal.success({
                                            title: "Success!",
                                            content: "Category successfully deleted."
                                        })
                                    });
                                },
                                onCancel() {},
                            })
                        }}
                            icon="delete"
                    />

                </>
            ),
        }
    ];

    return (
        <AdminLayout {...props}>
            <div>
                <div className="page-actions">
                    <Button type="primary" style={{marginBottom: 10}} onClick={() => {
                        setAddMenuCategoryModal(true);
                    }}>
                        <span className="nav-text">Add Menu Category</span>

                    </Button>
                </div>
                <Card title="Menu Categories">
                    <Table
                        size="small"
                        loading={loadingMenus}
                        columns={columns}
                        dataSource={menusCategories}
                    />
                </Card>
            </div>
            <Modal
                title={`${single ? "Update" : "Create"} Menu Category`}
                visible={addMenuCategoryModal}
                onCancel={() => {
                    setAddMenuCategoryModal(false);
                }}
                maskClosable={false}
                closable={false}
                footer={null}
            >
                <AddMenuCategory key={single ? single.key : "AddCategory"} single={single} onSuccess={() => {
                    setAddMenuCategoryModal(false);
                    setSingle(false);
                }} onCancel={() => {
                    setAddMenuCategoryModal(false);
                    setSingle(false);
                }}/>
            </Modal>
        </AdminLayout>
    );
};

export default MenuCategories;
