import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Input, Modal, Table} from "antd";
import AdminLayout from "../../components/AdminLayout";
import firebase from "../../firebase";
import AddMenuItem from "./AddMenuItem";

const { Search } = Input;

const Menus = (props) => {
    const [loadingMenus, setLoadingMenus] = useState(false);
    const [addMenuModal, setAddMenuModal] = useState(false);
    const [menus, setMenus] = useState([]);
    const [filteredMenus, setFilteredMenus] = useState([]);
    const [single, setSingle] = useState(false);

    useEffect(() => {
        loadMenus();
    }, [])

    const loadMenus = () => {
        setLoadingMenus(true);
        firebase.getAllMenuItmes((resp) => {
            setMenus(resp);
            setFilteredMenus(resp);
            setLoadingMenus(false);
        });
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            sorter: (a, b) => a.price - b.price,
            render: text => {
                return parseFloat(text).toFixed(2);
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button type="primary" size="small" style={{marginRight:"5px"}}  onClick={() => {
                        setSingle(record);
                        setAddMenuModal(true);
                    }}>
                        Edit
                    </Button>
                    <Button
                        type="danger"
                        size="small"
                        onClick={() => {
                            Modal.confirm({
                                title: 'Do you want to delete this menu?',
                                content: 'You cannot retrieve once you deleted.',
                                onOk() {
                                    let driverRef = firebase.db.ref('restaurant_menu/' + record.restaurant+'/'+record.key);
                                    driverRef.remove().then(() => {
                                        Modal.success({
                                            content: 'Menu has been successfully deleted!'
                                        });
                                    });
                                },
                                onCancel() {
                                },
                            });
                        }}
                    >
                        Delete
                    </Button>

                </>
            ),
        }
    ];

    return (
        <AdminLayout {...props}>
            <div>
                <div className="page-actions">
                    <Button type="primary" style={{ marginBottom: 10 }} onClick={() => {
                        setAddMenuModal(true);
                    }}>
                        <span className="nav-text">Add Menu Item</span>
                    </Button>
                </div>
                <div className="card card-block">
                    <div className="card-header">
                        <h2>Menu Items</h2>
                        <div>
                            <Search
                                placeholder="Search Menu Item(s) by name"
                                onChange={e => {
                                    const thfilteredMenus = menus.filter(({ name }) => {
                                        name = name.toLowerCase();
                                        return name.includes(e.target.value.toLowerCase());
                                    });
                                    setFilteredMenus(thfilteredMenus);
                                }}
                                onSearch={value => {
                                    const thfilteredMenus = menus.filter(({ name }) => {
                                        name = name.toLowerCase();
                                        return name.includes(value.toLowerCase());
                                    });
                                    setFilteredMenus(thfilteredMenus);
                                }}
                                style={{ width: 300 }}
                                allowClear={true}
                            />
                        </div>
                    </div>
                    <div className="card-body">
                        <Table
                            size="small"
                            loading={loadingMenus}
                            columns={columns}
                            dataSource={filteredMenus}
                        />
                    </div>
                </div>
            </div>
            <Modal
                title="Create New Menu"
                visible={addMenuModal}
                onCancel={() => {
                    setAddMenuModal(false);
                }}
                maskClosable={false}
                closable={false}
                footer={null}
            >
                <AddMenuItem key={single ? single.key : "AddMenuItem"} single={single} onSuccess={() => {
                    setAddMenuModal(false);
                    setSingle(false);
                }} onCancel={() => {
                    setAddMenuModal(false);
                    setSingle(false);
                }} />
            </Modal>
        </AdminLayout>
    );
};

export default Menus;
