import React from 'react'

import {
  Form, Icon, Input, Button, Modal
} from 'antd';

import firebase from '../firebase'

class LoginFormWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoggingin: false
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.login(values.email, values.password);
      }
    });
  }

  async login(email, password) {
    try {
      this.setState({
        isLoggingin: true
      });

      await firebase.login(email, password);

      this.props.onSuccess(true);
    } catch (error) {
      Modal.error({
        title: 'Sorry!',
        content: error.message,
      });
    } finally {
      this.setState({
        isLoggingin: false
      });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} className="login-form" style={{width: '85%'}}>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input type='email' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your password!' }],
          })(
            <Input type='password' prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Password" />
          )}
        </Form.Item>
        <Form.Item>
          <a className="login-form-forgot" href="/forgot-password">Forgot password?</a>
          <Button disabled={this.state.isLoggingin} type="primary" htmlType="submit" block>
            {
              this.state.isLoggingin ?
                <span>Please wait . . .</span> :
                <span>Log in</span>
            }
          </Button>
        </Form.Item>
      </Form>
    )
  }

}

const LoginForm = Form.create()(LoginFormWrapper);

export default LoginForm
